import * as React from "react"
import type { SVGProps } from "react"
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.5 11.832h4.167a.5.5 0 0 0 .5-.5v-1.011a2.1 2.1 0 0 0-1.161-1.878l-.898-.45a1.1 1.1 0 0 1-.608-.983V4.499h.167a1.833 1.833 0 0 0 0-3.667H5.333a1.833 1.833 0 1 0 0 3.667H5.5v2.51a1.1 1.1 0 0 1-.608.985l-.898.449a2.1 2.1 0 0 0-1.16 1.878v1.011a.5.5 0 0 0 .5.5H7.5v2.833a.5.5 0 0 0 1 0zm-4-9.167c0-.46.373-.833.833-.833h5.334a.833.833 0 0 1 0 1.667H10a.5.5 0 0 0-.5.5v3.01a2.1 2.1 0 0 0 1.16 1.88l.899.448a1.1 1.1 0 0 1 .608.984v.511H3.833v-.511a1.1 1.1 0 0 1 .608-.984l.898-.449A2.1 2.1 0 0 0 6.5 7.01V3.999a.5.5 0 0 0-.5-.5h-.666a.833.833 0 0 1-.833-.834"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgPin
