import { create } from "zustand"

type MobileDrawerStore = {
  open: boolean
  toggle: (open: boolean) => void
}

export const useMobileDrawerMenu = create<MobileDrawerStore>((set) => ({
  open: false,
  toggle: (open: boolean) => set({ open }),
}))
