import { SvgPwa } from "@store-platform/ui/icons"
import { Tooltip } from "../Tooltip/Tooltip"
import { cn } from "@store-platform/utils"

export function PWAIcon({ className }: { className?: string }) {
  return (
    <Tooltip caption="This is an installable web app">
      <span>
        <SvgPwa className={cn("text-yellow-700 h-5 w-5", className)} />
      </span>
    </Tooltip>
  )
}

export default PWAIcon
