"use client"

import {
  FormFieldFileAutoSave,
  ImageCropper,
  InfoBox,
  Loader,
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalTitle,
} from ".."
import { useState } from "react"
import { useUploadImageModal } from "../../hooks"

export function ImageUploadModal() {
  const [image, setImage] = useState<File | null>(null)
  const [processing, setProcessing] = useState(false)

  const { payload, isOpen, onOpenChange } = useUploadImageModal()

  const openChange = (open: boolean) => {
    onOpenChange(open)
    if (!open) setImage(null)
  }

  return (
    <ResponsiveModal open={isOpen} onOpenChange={(open) => openChange(open)}>
      <ResponsiveModalContent className="!bg-gray-50 sm:w-[600px] sm:h-[800px] sm:max-w-none flex flex-col gap-2 overflow-auto justify-between">
        <ResponsiveModalTitle className="text-left text-3xl text-gray-900 font-semibold mb-6">
          {payload.title}
        </ResponsiveModalTitle>
        {processing ? (
          <div className="m-auto h-full flex align-middle items-center">
            <Loader size={60} />
          </div>
        ) : (
          <>
            <div className="flex justify-center">
              <FormFieldFileAutoSave
                label={
                  image ? "Upload a different Image" : "Upload your new Image"
                }
                accept={["image/*"]}
                action={setImage}
              />
            </div>
            {!image ? (
              <InfoBox message="You will be able to adjust your image after uploading it" />
            ) : (
              <ImageCropper
                image={image}
                shape={payload.shape}
                aspectRatio={payload.aspectRatio}
                outputFormat={payload.outputFormat}
                onSubmit={async (file: File | string) => {
                  setProcessing(true)
                  await payload.action(file as string & File)
                  openChange(false)
                  setProcessing(false)
                }}
              />
            )}
          </>
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
