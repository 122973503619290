"use client"

import { useAttributeState } from "@store-platform/ui/common"
import { getAttributeRelationships } from "../../app/(platform)/actions"
import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"

export const AttributeStateProvider = () => {
  const setData = useAttributeState(useShallow((state) => state.setData))

  useEffect(() => {
    const initialize = async () => {
      const relationships = await getAttributeRelationships()
      if (relationships) setData(relationships)
    }
    initialize()
  }, [setData])

  return null
}
