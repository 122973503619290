"use client"
import { SvgIosShare, SvgPlusSquare } from "@store-platform/ui/icons"
import { useUserAgentBrowser, useUserAgentOS } from "../../hooks"

const iOSteps = [
  <>Launch the app</>,
  <>
    Tap
    <SvgIosShare className="h-5 w-5 text-blue-500 mx-1" />
    on your browser toolbar
  </>,
  <>
    Select "<p className="text-blue-500">Add to Home Screen</p>
    <SvgPlusSquare className="text-blue-500 ml-1" />"
  </>,
  <>
    A popup will appear, click "<p className="text-blue-500">Add</p>"
  </>,
]

const macSafariSteps = [
  <>Launch the app</>,
  <>
    Tap
    <SvgIosShare className="h-5 w-5 text-blue-500 mx-1" />
    on your browser toolbar
  </>,
  <>
    Select "<p className="text-blue-500">Add to Dock</p>"
  </>,
  <>
    A popup will appear, click "<p className="text-blue-500">Add</p>"
  </>,
]

export const InstallInstructions = () => {
  const uaOS = useUserAgentOS()?.name
  const uaBrowser = useUserAgentBrowser()?.name
  let steps: JSX.Element[] = []
  if (uaOS === "iOS") {
    steps = iOSteps
  } else if (uaOS === "macOS" && uaBrowser === "Safari") {
    steps = macSafariSteps
  } else {
    return
  }

  return (
    <div className="bg-white rounded-lg flex flex-col">
      {steps.map((step, index) => (
        <div
          key={index}
          className="px-3 py-2 inline-flex items-center rounded-lg"
        >
          <p className="p-1 mr-1">{index + 1}.</p>
          <span className="inline-flex items-center whitespace-pre-wrap">
            {step}
          </span>
        </div>
      ))}
    </div>
  )
}
