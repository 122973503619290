"use client"

import {
  DialogDescription,
  DialogTitle,
  LaunchModalContent,
  ResponsiveModal,
  ResponsiveModalContent,
  useLaunchModal,
  VisuallyHidden,
} from "@store-platform/ui/common"

export const LaunchModal = () => {
  const { payload, isOpen, onOpenChange } = useLaunchModal()
  return (
    <ResponsiveModal open={isOpen} onOpenChange={onOpenChange}>
      <ResponsiveModalContent className="!bg-gray-50">
        <VisuallyHidden>
          <DialogTitle>Get the App {payload.listingId}</DialogTitle>
          <DialogDescription>
            You can select your preferred platform to get the app
          </DialogDescription>
        </VisuallyHidden>
        <LaunchModalContent {...payload} />
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
