"use client"

import * as Form from "@radix-ui/react-form"
import * as Slider from "@radix-ui/react-slider"
import { Control, Controller } from "react-hook-form"

export type FormFieldSliderProps = {
  label?: string
  name: string
  control: Control<any>
  min: number
  max: number
  step: number
  defaultValue?: number
  className?: string
}

export function FormFieldSlider(props: FormFieldSliderProps) {
  return (
    <Form.Field name={props.name} className={props.className}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <Slider.Root
            className="relative flex items-center select-none touch-none w-full h-5"
            defaultValue={props.defaultValue ? [props.defaultValue] : undefined}
            min={props.min}
            max={props.max}
            step={props.step}
            onValueChange={field.onChange}
            value={[field.value]}
          >
            <Slider.Track className="border border-gray-400 relative grow rounded-full h-[7px]">
              <Slider.Range className="absolute bg-white rounded-full h-full" />
            </Slider.Track>
            <Slider.Thumb
              className="block w-5 h-5 bg-blue-500 shadow-[0_1px_5px] shadow-gray-800 rounded-[10px] hover:bg-blue-500/90 focus:outline-none focus:shadow-[0_2px_10px] focus:shadow-gray-900"
              aria-label={props.label}
            />
          </Slider.Root>
        )}
      />
    </Form.Field>
  )
}
