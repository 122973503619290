"use client"

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  ProfilePicture,
  PwaInstallDialogTrigger,
  Separator,
  useAuthModal,
  useIsInstalled,
} from "@store-platform/ui/common"
import { ClaimedApp, CurrentUserResponse } from "@store-platform/types"
import {
  SvgBookOpen,
  SvgDownload,
  SvgLogOut,
  SvgMenu,
  SvgUser,
  SvgX,
} from "@store-platform/ui/icons"
import { SidebarContent } from "../app/(platform)/(main)/_layout/SidebarContent"
import { SidebarItem } from "../app/(platform)/(main)/_layout/SidebarItem"
import { SidebarFooter } from "../app/(platform)/(main)/_layout/SidebarFooter"
import { useMobileDrawerMenu } from "./hooks/useMobileDrawerMenu"
import { usePathname } from "next/navigation"
import { Suspense } from "react"
import { SignOutButton, useUser } from "@clerk/nextjs"
import { BackButton } from "./BackButton"

function backButtonAvailable(pathname: string): boolean {
  switch (true) {
    case pathname === "/":
    case pathname === "/foryou":
    case pathname.match(/^\/browse(?:\/.*)?$/) !== null:
    case pathname.match(/^\/developer\/?$/) !== null:
    case pathname.match(/^\/settings\//) !== null:
    case pathname.match(/^\/search\/.+$/) !== null:
      return false
    default:
      return true
  }
}

type MobileDrawerProps = {
  apps: ClaimedApp[] | null
}

export const MobileDrawer = (props: MobileDrawerProps) => {
  const { user } = useUser()
  const { open, toggle } = useMobileDrawerMenu()
  const { open: openAuthModal } = useAuthModal()
  const isInstalled = useIsInstalled()
  const pathname = usePathname()

  if (backButtonAvailable(pathname)) return <BackButton />

  return (
    <Drawer direction="left" open={open} onOpenChange={toggle}>
      <DrawerTrigger className="focus:ring-0 focus:outline-none">
        {!user ? (
          <SvgMenu />
        ) : (
          <ProfilePicture
            size="s"
            imgUrl={user.imageUrl}
            fallback={user.fullName || ""}
            unoptimized
          />
        )}
      </DrawerTrigger>
      <DrawerContent
        withDragBar={false}
        className="bg-white flex flex-col w-3/4 fixed top-0 bottom-0 left-0 rounded-r-lg mt-0 rounded-tl-none p-1 pt-6 focus:outline-none"
      >
        <DrawerTitle className="hidden">Left Drawer Menu</DrawerTitle>
        <DrawerDescription className="hidden">
          Left side drawer menu for mobile devices
        </DrawerDescription>
        <SvgX
          className="absolute top-4 right-4 w-5 h-5 text-gray-700"
          onClick={() => toggle(false)}
        />
        {!!user && (
          <DrawerHeader className="pt-0">
            <section className="flex items-center gap-2 mb-4">
              <ProfilePicture
                size="s"
                imgUrl={user.imageUrl}
                fallback={user.fullName || ""}
                unoptimized
              />
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                  {user.fullName || ""}
                </p>
                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                  @{user.username}
                </p>
              </div>
            </section>
            <Separator />
          </DrawerHeader>
        )}
        <Suspense fallback={null}>
          <SidebarContent apps={props.apps} mobileDrawer={true} />
        </Suspense>
        <DrawerFooter>
          {user ? (
            <>
              <SidebarItem
                href="/about"
                prefetch={false}
                label="About Us"
                icon={<SvgBookOpen width={24} height={24} />}
                mobileDrawer
              />
              <SignOutButton redirectUrl="/">
                <SidebarItem
                  label="Log out"
                  href=""
                  icon={<SvgLogOut width={24} height={24} />}
                  align="start"
                  className="h-11"
                  mobileDrawer
                />
              </SignOutButton>
            </>
          ) : (
            <SidebarItem
              label="Log in or Sign up"
              href="#"
              onClick={() => openAuthModal()}
              icon={<SvgUser width={24} height={24} />}
              align="center"
              className="pl-0 h-11"
              mobileDrawer
              cta
              asButton
            />
          )}
          <Separator className="mt-3 mb-3" />
          {!isInstalled && (
            <PwaInstallDialogTrigger
              variant="secondary"
              className="w-full"
              trackingSource="Mobile Sidebar"
              sideEffect={() => toggle(false)}
            >
              <SvgDownload width={24} height={24} />
              <span>Install App</span>
            </PwaInstallDialogTrigger>
          )}
          <SidebarFooter mobileDrawer={true} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
