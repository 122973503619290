"use client"

import {
  Button,
  DialogDescription,
  DialogTitle,
  ResponsiveModal,
  ResponsiveModalContent,
  useAccountSettingsModal,
  useIsMobile,
  VisuallyHidden,
} from "@store-platform/ui/common"
import { UserProfile } from "@clerk/nextjs"
import { useRouter } from "next/navigation"
import { cn, defaultUserProfileElementStyling } from "@store-platform/utils"

export const AccountSettingsModal = () => {
  const { isOpen, onOpenChange } = useAccountSettingsModal()
  const mobile = useIsMobile()
  const router = useRouter()

  return (
    <ResponsiveModal
      open={isOpen}
      onOpenChange={(open) => {
        onOpenChange(open)
        if (!open) {
          // remove hash from URL
          router.replace(window.location.pathname)
        }
      }}
    >
      <ResponsiveModalContent className="pb-0 px-0 sm:!p-0 max-w-[880px]">
        <VisuallyHidden>
          <DialogTitle>Account Management</DialogTitle>
          <DialogDescription>
            Manage your account data and security settings
          </DialogDescription>
        </VisuallyHidden>
        <UserProfile
          routing="hash"
          appearance={{
            elements: {
              ...(mobile
                ? {
                    rootBox: {
                      marginTop: "20px",
                    },
                    cardBox: {
                      width: "100vw",
                      maxWidth: "100vw",
                    },
                  }
                : {}),
              ...defaultUserProfileElementStyling,
            },
          }}
        />
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}

type AccountSettingsModalTriggerProps = {
  label: string
  className?: string
  landingTab?: "security" | "profile"
}

export const AccountSettingsModalTrigger = (
  props: AccountSettingsModalTriggerProps,
) => {
  const { open } = useAccountSettingsModal()

  return (
    <Button
      onClick={() => {
        open(undefined)
        window.location.hash =
          props.landingTab === "security" ? "#/security" : ""
      }}
      variant="secondary"
      size="sm"
      className={cn("w-fit", props.className)}
    >
      {props.label}
    </Button>
  )
}
