"use client"

import { BaseModalStoreType, Review } from "@store-platform/types"
import { parseAsBoolean, useQueryState } from "nuqs"
import { create } from "zustand"

type ReviewsModalState = {
  reviews: Review[]
  showAppIcon: boolean
}

/**
 * To manage the data within the modal
 */
const useReviewsModalStore = create<
  ReviewsModalState & { setStore: (state: Partial<ReviewsModalState>) => void }
>((set) => ({
  reviews: [],
  showAppIcon: false,
  setStore: set,
}))

export const useReviewsModal = () => {
  const [query, setQuery] = useQueryState("reviews_modal", parseAsBoolean)
  const { setStore, ...payload } = useReviewsModalStore()

  return {
    isOpen: query,
    open: async (payload: Partial<ReviewsModalState>) => {
      setStore({ ...payload })
      await setQuery(true, { history: "push" })
    },
    onOpenChange: async (open: boolean) => {
      if (open) return
      await setQuery(null)
    },
    payload,
  } as BaseModalStoreType<ReviewsModalState>
}
