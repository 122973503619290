import * as React from "react"
import type { SVGProps } from "react"
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10.625 2.5a.625.625 0 1 0-1.25 0v8.491l-3.1-3.1a.625.625 0 1 0-.884.884l4.167 4.167a.622.622 0 0 0 .884 0l4.167-4.167a.625.625 0 1 0-.884-.884l-3.1 3.1z" />
    <path d="M2.5 11.875c.345 0 .625.28.625.625v3.333a1.042 1.042 0 0 0 1.042 1.042h11.666a1.041 1.041 0 0 0 1.042-1.042V12.5a.625.625 0 1 1 1.25 0v3.333a2.292 2.292 0 0 1-2.292 2.292H4.167a2.292 2.292 0 0 1-2.292-2.292V12.5c0-.345.28-.625.625-.625" />
  </svg>
)
export default SvgDownload
