"use client"
import Image from "next/image"
import { cn, nameInitials } from "@store-platform/utils"

export interface ProfilePictureProps {
  size: "s" | "m" | "l"
  imgUrl?: string
  fallback: string
  className?: string
  priority?: boolean
  unoptimized?: boolean
}

export function ProfilePicture(props: ProfilePictureProps) {
  let sizePx = 32
  if (props.size === "m") sizePx = 72
  if (props.size === "l") sizePx = 115

  return (
    <div
      className={cn("inline-flex aspect-square rounded-full", {
        "size-[32px]": props.size === "s",
        "size-[72px]": props.size === "m",
        "size-[115px]": props.size === "l",
      })}
    >
      {props.imgUrl ? (
        <Image
          src={props.imgUrl || ""}
          alt={`${props.fallback} profile picture`}
          width={sizePx}
          height={sizePx}
          priority={props.priority}
          className="aspect-square rounded-full"
          unoptimized={props.unoptimized}
        />
      ) : (
        <div
          className={cn(
            "bg-gray-500 text-white aspect-square rounded-full flex justify-center items-center w-full h-full",
            {
              "text-[14px]": props.size === "s",
              "text-[35px]": props.size === "m",
              "text-[50px]": props.size === "l",
            },
          )}
        >
          {nameInitials(props.fallback)}
        </div>
      )}
    </div>
  )
}
