import { useEffect, useState } from "react"
import { isEmpty } from "lodash-es"

export function useRecentSearches(maxCount = 5) {
  const [_recentSearches, setRecentSearches] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem("recentSearches") || "[]") || []
  })
  const recentSearches = _recentSearches.filter(
    (s) =>
      !isEmpty(s) &&
      s.length > 2 &&
      !_recentSearches.find((n) => s !== n && n.includes(s)),
  )

  useEffect(() => {
    const handleStorageChange = () => {
      setRecentSearches(
        JSON.parse(localStorage.getItem("recentSearches") || "[]") || [],
      )
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  const addRecentSearch = (query: string) => {
    if (!query || isEmpty(query)) return
    let searches = recentSearches.filter((search) => search !== query)
    searches.unshift(query)
    searches = searches.slice(0, maxCount)
    localStorage.setItem("recentSearches", JSON.stringify(searches))
    setRecentSearches(searches)
  }

  const clearRecentSearch = () => {
    localStorage.removeItem("recentSearches")
    setRecentSearches([])
  }

  return { recentSearches, addRecentSearch, clearRecentSearch }
}
