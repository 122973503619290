import { create } from "zustand"
import { BaseModalStoreType } from "@store-platform/types"

type DevAccessModalState = void

export const useDevAccessModal = create<
  BaseModalStoreType<DevAccessModalState>
>((set) => ({
  payload: undefined,
  isOpen: false,
  open: (payload) => {
    set({ isOpen: true, payload })
  },
  onOpenChange: (isOpen: boolean) => set({ isOpen }),
}))
