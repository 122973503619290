"use client"
import {
  cn,
  generateBrowsingURL,
  getToggleAttributeLink,
} from "@store-platform/utils"
import {
  Attribute,
  ParsedBrowsingState,
  RatingFilterQuery,
  SortQuery,
} from "@store-platform/types"
import {
  Button,
  Checkbox,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Link,
  ResponsiveModal,
  ResponsiveModalContent,
  Separator,
} from ".."
import { Label } from "../Label/Label"
import { capitalize } from "lodash-es"
import {
  useAttributeState,
  useClientBrowseState,
  useIsMobile,
  useMoreFiltersModal,
} from "../../hooks"
import {
  SvgArrowDownNarrowWide,
  SvgChevronDown,
  SvgStar,
} from "@store-platform/ui/icons"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../Select/Select"
import { useRouter } from "next/navigation"

export const MoreFiltersModal = () => {
  const state = useMoreFiltersModal()
  return (
    <ResponsiveModal open={state.isOpen} onOpenChange={state.onOpenChange}>
      <ResponsiveModalContent className="bg-white sm:max-w-none sm:!w-[700px] sm:h-[500px]">
        <Content onOpenChange={state.onOpenChange} />
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}

const sortByQueries: { text: string; query: SortQuery }[] = [
  { text: "Relevance", query: "relevance" },
  { text: "Newest", query: "newest" },
  { text: "Highest Rated", query: "highest_rated" },
  { text: "Most Reviewed", query: "most_reviewed" },
]

const ratingFilterQueries: {
  text: string
  query: RatingFilterQuery | "any"
}[] = [
  { text: "Any", query: "any" },
  { text: "4.5 and above", query: "4.5" },
  { text: "4 and above", query: "4" },
  { text: "3.5 and above", query: "3.5" },
  { text: "3 and above", query: "3" },
]

const Content = (props: { onOpenChange: (open: boolean) => void }) => {
  const { attributeRelationships } = useAttributeState()
  const browsingState = useClientBrowseState()
  const isMobile = useIsMobile()

  return (
    <Command data-vaul-no-drag>
      <CommandInput placeholder="Search..." />
      <CommandList className="scrollbar-thin scrollbar-floating sm:max-h-fit pt-2 max-sm:max-h-[600px]">
        <div className="grid sm:grid-cols-2 gap-x-4 px-2 gap-y-2">
          <RadioSection
            title={
              <div className="flex flex-row gap-x-1 items-center justify-start text-base text-black">
                Sort <SvgArrowDownNarrowWide className="w-4 h-4" />
              </div>
            }
            queries={sortByQueries}
            paramKey="sort_by"
            getSelectedValue={() =>
              browsingState.filters?.sort_by ?? "relevance"
            }
            defaultValue="relevance"
            browsingState={browsingState}
          />
          <RadioSection
            title={
              <div className="flex flex-row gap-x-1 items-center justify-start text-base text-black">
                Rating <SvgStar className="w-4 h-4 fill-gray-700" />
              </div>
            }
            queries={ratingFilterQueries}
            paramKey="min_rating"
            getSelectedValue={() => browsingState.filters?.min_rating ?? "any"}
            defaultValue="any"
            browsingState={browsingState}
          />
        </div>
        {attributeRelationships?.buckets &&
          Object.entries(attributeRelationships.buckets).map(
            ([bucketName, attributes]) => (
              <CheckBoxFilterSection
                key={bucketName}
                title={capitalize(bucketName)}
                queries={attributes.map((attribute) => ({
                  text: attribute.name,
                  query: attribute.slug,
                }))}
                browsingState={browsingState}
              />
            ),
          )}
        <CheckBoxFilterSection
          key="floating"
          title="Other"
          queries={
            attributeRelationships?.floating
              ? Object.values(attributeRelationships.floating).map(
                  (attribute) => ({
                    text: attribute.name,
                    query: attribute.slug,
                  }),
                )
              : []
          }
          browsingState={browsingState}
        />
        <CommandEmpty>No filters found</CommandEmpty>
      </CommandList>
      <Separator className="w-full" />
      <div className="w-full flex justify-end mt-2">
        <div className="flex flex-row gap-x-2">
          <Link
            variant="naked"
            className="bg-blue-50 text-blue-500 hover:bg-blue-100 h-9 px-4 sm:h-11 sm:px-4 sm:py-2 rounded-md text-sm flex justify-center items-center"
            href={generateBrowsingURL({}, {})}
          >
            Clear All
          </Link>
          <Button
            variant="default"
            size={isMobile ? "sm" : "default"}
            onClick={() => props.onOpenChange(false)}
          >
            Done
          </Button>
        </div>
      </div>
    </Command>
  )
}

type RadioSectionProps = {
  title: React.ReactNode
  queries: { text: string; query: any }[]
  paramKey: "sort_by" | "min_rating"
  getSelectedValue: () => string | null
  defaultValue?: string
  browsingState: ParsedBrowsingState
}

const RadioSection = (props: RadioSectionProps) => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return <MobileRadioSelect {...props} />
  }
  return <DesktopRadioSelect {...props} />
}

const MobileRadioSelect = (props: RadioSectionProps) => {
  const router = useRouter()
  return (
    <div className="flex flex-col">
      <Label htmlFor={props.paramKey} className="text-gray-700 pb-1">
        {props.title}
      </Label>
      <div className="relative">
        <div className="flex flex-row justify-between items-center outline outline-1 outline-gray-100 rounded-md p-2 gap-x-2 text-gray-500">
          {
            props.queries.find(
              (item) =>
                item.query ===
                (props.browsingState.filters?.[props.paramKey] ||
                  props.defaultValue),
            )?.text
          }
          <SvgChevronDown className="w-4 h-4" />
        </div>
        <select
          className="appearance-none absolute inset-0 opacity-0"
          value={props.getSelectedValue() ?? undefined}
          onChange={(e) => {
            const query = e.target.value
            router.push(
              generateBrowsingURL(props.browsingState, {
                ...props.browsingState,
                filters: {
                  ...props.browsingState.filters,
                  [props.paramKey]:
                    query === props.defaultValue ? undefined : query,
                },
              }),
            )
          }}
        >
          {props.queries.map((item) => (
            <option key={item.query} value={item.query}>
              {item.text}
            </option>
          ))}
          <SvgChevronDown className="w-4 h-4" />
        </select>
      </div>
    </div>
  )
}

const DesktopRadioSelect = (props: RadioSectionProps) => {
  const router = useRouter()

  return (
    <Select
      defaultValue={props.defaultValue}
      value={props.getSelectedValue() ?? undefined}
      onValueChange={(value) => {
        router.push(
          generateBrowsingURL(props.browsingState, {
            ...props.browsingState,
            filters: {
              ...props.browsingState.filters,
              [props.paramKey]:
                value === props.defaultValue ? undefined : value,
            },
          }),
        )
      }}
    >
      <div className="flex flex-col text-gray-700">
        <Label className="flex flex-row gap-x-1 items-center justify-start px-2 pb-1 font-medium text-black">
          {props.title}
        </Label>
        <SelectTrigger className="flex flex-row items-center justify-between px-2 outline outline-1 outline-gray-500 rounded-lg gap-x-1 without-ring">
          {
            props.queries.find(
              (item) =>
                item.query ===
                (props.browsingState.filters?.[props.paramKey] ||
                  props.defaultValue),
            )?.text
          }
        </SelectTrigger>
        <SelectContent align="start" className="bg-white">
          {props.queries.map((item) => (
            <SelectItem
              key={item.query}
              value={item.query}
              className="text-gray-700 cursor-pointer data-[state=checked]:text-blue-500"
            >
              {item.text}
            </SelectItem>
          ))}
        </SelectContent>
      </div>
    </Select>
  )
}

const CheckBoxFilterSection = (props: {
  title: string
  queries: { text: string; query: any }[]
  browsingState: ParsedBrowsingState
}) => {
  return (
    <CommandGroup
      className="[&_[cmdk-group-heading]]:text-base"
      heading={props.title}
    >
      <div className="grid sm:grid-cols-3">
        {props.queries.map((item) => (
          <CommandItem key={item.query} className="py-1">
            <FilterToggle
              browsingState={props.browsingState}
              attribute={{ name: item.text, slug: item.query }}
            />
          </CommandItem>
        ))}
      </div>
    </CommandGroup>
  )
}

const FilterToggle = ({
  browsingState,
  attribute,
}: {
  browsingState: ParsedBrowsingState
  attribute: Attribute
}) => {
  const checked = browsingState.attributes?.includes(attribute.slug) ?? false

  return (
    <Link
      prefetch={false}
      variant="naked"
      href={getToggleAttributeLink(browsingState, attribute.slug, !checked)}
      className="flex items-center space-x-2 overflow-hidden"
    >
      <Checkbox
        checked={checked}
        id={attribute.slug}
        className={cn("border-gray-700", checked && "stroke-blue-500")}
      />
      <Label
        className={cn(
          "cursor-pointer text-base leading-5 truncate",
          checked ? "text-blue-500 font-semibold" : "font-light text-gray-700",
        )}
        htmlFor={attribute.slug}
      >
        {attribute.name}
      </Label>
    </Link>
  )
}
