import { cn } from "@store-platform/utils"
import { SvgStar } from "@store-platform/ui/icons"

const getStarWidth = (rating: number, ratingAverage: number): number => {
  if (Math.floor(ratingAverage) !== rating) return 100
  const w = (ratingAverage % 1) * 100
  switch (true) {
    case w <= 10:
      return 0
    case w <= 25:
      return 25
    default:
      return w
  }
}

interface StarRatingProps {
  size?: number
  rating?: number
  className?: string
}

export const StarRating = (props: StarRatingProps) => {
  const avgRating = props.rating || 0

  return (
    <div className={cn("flex items-center justify-start", props.className)}>
      {[0, 1, 2, 3, 4].map((rating) => (
        <div key={rating} className="relative flex focus:outline-none">
          <SvgStar
            className="flex-shrink-0 text-gray-200"
            fill="currentColor"
            width={props.size || 20}
            height={props.size || 20}
            aria-hidden="true"
          />
          <div
            style={{
              width: `${getStarWidth(rating, avgRating)}%`,
            }}
            className={cn("absolute overflow-hidden", {
              hidden: avgRating <= rating,
            })}
          >
            <SvgStar
              className="text-blue-500"
              fill="currentColor"
              stroke="inherit"
              width={props.size || 20}
              height={props.size || 20}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
