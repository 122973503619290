import * as React from "react"
import type { SVGProps } from "react"
const SvgBookOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M1.042 2.5c0-.345.28-.625.625-.625h5a3.96 3.96 0 0 1 3.958 3.958V17.5a.625.625 0 1 1-1.25 0A1.875 1.875 0 0 0 7.5 15.625H1.667A.625.625 0 0 1 1.042 15zM9.375 15V5.833a2.71 2.71 0 0 0-2.708-2.708H2.292v11.25H7.5c.68 0 1.337.222 1.875.625"
      clipRule="evenodd"
    />
    <path
      d="M10.534 3.034a3.96 3.96 0 0 1 2.8-1.159h5c.345 0 .624.28.624.625V15c0 .345-.28.625-.625.625H12.5a1.875 1.875 0 0 0-1.875 1.875.625.625 0 1 1-1.25 0V5.833c0-1.05.417-2.056 1.16-2.799M10.625 15a3.13 3.13 0 0 1 1.875-.625h5.208V3.125h-4.375a2.71 2.71 0 0 0-2.708 2.708z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBookOpen
