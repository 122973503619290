"use client"
import { useSetClientState } from "@store-platform/ui/common"
import { useEffect } from "react"

export const InstalledObserver = () => {
  const set = useSetClientState()

  useEffect(() => {
    const mediaQuery = window.matchMedia("(display-mode: standalone)")
    const handler = (event: any) => set({ isInstalled: !!event.matches })
    handler(mediaQuery)

    mediaQuery.addEventListener("change", handler)
    return () => mediaQuery.removeEventListener("change", handler)
  }, [set])
  return <></>
}
