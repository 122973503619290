"use client"
import { useSetClientState } from "@store-platform/ui/common"
import { throttle } from "lodash-es"
import { useEffect } from "react"

export const ScreenWidthObserver = () => {
  const set = useSetClientState()

  useEffect(() => {
    const debouncedHandleResize = throttle(() => {
      set({ isMobile: window.innerWidth <= 640 })
    }, 500)

    window.addEventListener("resize", debouncedHandleResize)
    debouncedHandleResize()
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [set])
  return <></>
}
