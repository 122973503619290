"use client"

import { create } from "zustand"
import { BaseModalStoreType } from "@store-platform/types"

export interface AlertDialogProps {
  title: string
  description: string
  cancel?: boolean
  confirmText: string
  onConfirm: () => void
  onCancel?: () => void
}

export const useAlertDialog = create<BaseModalStoreType<AlertDialogProps>>(
  (set) => ({
    payload: {
      title: "Are you sure?",
      description: "This action cannot be undone",
      confirmText: "Confirm",
      onConfirm: () => {},
    },
    isOpen: false,
    open: (payload) => set({ isOpen: true, payload }),
    onOpenChange: (open: boolean) => set({ isOpen: open }),
  }),
)
