"use client"
import { MouseEventHandler, SVGProps } from "react"
import { Button } from "../Button/Button"
import { Link } from "../Link/Link"
import { cn } from "@store-platform/utils"

type ButtonProps = {
  text: string
  onClick?: MouseEventHandler
  active?: boolean
  Icon: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
  Component: typeof Button | typeof Link
  href?: string
}
export const MobileHomeFooterButton = ({
  text,
  onClick,
  active,
  Icon,
  Component,
  href,
}: ButtonProps) => {
  return (
    <Component
      variant="naked"
      href={(Component === Link && href) || ""}
      className="flex justify-center basis-1/4 relative"
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center gap-y-1">
        <Icon
          className={cn(
            active ? "text-blue-500" : "text-gray-700",
            "h-6 w-6 flex-shrink-0",
          )}
          aria-hidden="true"
        />
        <p
          className={cn(
            "text-xs font-medium",
            active ? "text-blue-500" : "text-gray-700",
          )}
          aria-hidden="true"
        >
          {text}
        </p>
      </div>
    </Component>
  )
}
