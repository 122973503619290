import { SvgClaimedVerified } from "@store-platform/ui/icons"
import { Tooltip } from "../Tooltip/Tooltip"
import { Badge } from "../Badge/Badge"
import { cn } from "@store-platform/utils"

type ClaimVerifiedListingBadgeProps = {
  withText?: boolean
}

export const ClaimVerifiedListingBadge = ({
  withText,
}: ClaimVerifiedListingBadgeProps) => {
  return (
    <Tooltip caption="Listing claimed by verified owner">
      <Badge
        variant={withText ? "default" : "naked"}
        className={cn("cursor-default", { "p-1": !!withText })}
      >
        <SvgClaimedVerified className="fill-blue-500 h-5 w-5" />
        {withText && "Claimed & Verified"}
      </Badge>
    </Tooltip>
  )
}
