"use client"
import { EnrichedList, Listing } from "@store-platform/types"
import { Button } from "../Button/Button"
import { SvgClaimed, SvgPlus } from "@store-platform/ui/icons"
import { cn, getBestIcon } from "@store-platform/utils"
import { first } from "lodash-es"
import { selectIsAppInList, selectLists, useAddToList } from "../../hooks"
import { useShallow } from "zustand/react/shallow"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../Command/Command"
import { AppIcon } from "../AppIcon/AppIcon"

export const ListSelector = ({
  setCreateList,
}: {
  setCreateList: () => void
}) => {
  const { lists, listing = {} as Listing } = useAddToList(
    useShallow((state) => ({
      lists: selectLists(state),
      listing: state.payload?.listing,
    })),
  )

  return (
    <div className="gap-0 p-0 outline-none">
      <Command className="overflow-hidden rounded-t-none border-t p-0">
        <div className="mb-2 -pb-2 px-1">
          <CreateListBtn onClick={setCreateList} />
        </div>
        <CommandList>
          <CommandEmpty>No lists found.</CommandEmpty>
          {/* -mt-1 to account for gray padding under sticky create list btn */}
          <CommandGroup className="flex flex-1 flex-col overflow-y-scroll h-full w-full scrollbar-thin max-h-60 pt-0 -mr-1 -mt-1">
            {lists.map((list) => {
              return <ListItem key={list?.id} listing={listing} item={list} />
            })}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}

const CreateListBtn = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="naked"
    className="flex items-center p-2 mt-2 gap-x-4 cursor-pointer rounded-md bg-white text-blue-500 hover:bg-opacity-50 w-full"
    onClick={onClick}
  >
    <div className="flex items-center justify-center bg-blue-50 rounded-lg h-11 w-11">
      <SvgPlus className="h-5 w-5" />
    </div>
    Create a new List
  </Button>
)

const ListItem = ({
  listing,
  item,
}: {
  item: EnrichedList
  listing: Listing
}) => {
  const { addAppToList, removeAppFromList } = useAddToList()
  const checked = useAddToList(selectIsAppInList(item.id, listing.id))

  return (
    <CommandItem
      key={item.id}
      className={cn(
        "flex items-center py-2 my-2 gap-x-4 cursor-pointer rounded-md",
        checked
          ? "bg-blue-50 outline-[1px] outline-blue-500 -outline-offset-1"
          : "bg-white hover:bg-opacity-50",
      )}
      onSelect={
        checked
          ? () => removeAppFromList(item?.id, item?.slug)
          : () => addAppToList(item?.id, item?.slug)
      }
    >
      <AppIcon
        key={item.id}
        imgUrl={getBestIcon(first(item.listings)?.icons)?.src}
        name={item.name}
        size={48}
        className="h-10 w-10"
      />
      <span className="ml-2 font-normal">{item?.name}</span>
      {checked ? (
        <SvgClaimed className="h-5 w-5 text-blue-500 ml-auto mr-2" />
      ) : null}
    </CommandItem>
  )
}
