import * as React from "react"
import type { SVGProps } from "react"
const SvgDevices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M3.333 2.708A2.292 2.292 0 0 0 1.042 5v5.833a2.292 2.292 0 0 0 2.291 2.292h4.375v2.083H5.833a.625.625 0 1 0 0 1.25H10a.625.625 0 1 0 0-1.25H8.958v-2.083H10a.625.625 0 1 0 0-1.25H3.333a1.042 1.042 0 0 1-1.041-1.042V5a1.042 1.042 0 0 1 1.041-1.042h10A1.042 1.042 0 0 1 14.375 5v1.667a.625.625 0 1 0 1.25 0V5a2.292 2.292 0 0 0-2.292-2.292z" />
    <path
      fillRule="evenodd"
      d="M15 9.375a2.292 2.292 0 0 0-2.292 2.292v5A2.292 2.292 0 0 0 15 18.958h1.667a2.292 2.292 0 0 0 2.291-2.291v-5a2.292 2.292 0 0 0-2.291-2.292zm-1.042 2.292c0-.576.467-1.042 1.042-1.042h1.667c.575 0 1.041.466 1.041 1.042v5c0 .575-.466 1.041-1.041 1.041H15a1.042 1.042 0 0 1-1.042-1.041z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgDevices
