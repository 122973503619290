import * as React from "react"
import type { SVGProps } from "react"
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.558 7.058a.625.625 0 0 1 .884 0L10 11.616l4.558-4.558a.625.625 0 1 1 .884.884l-5 5a.625.625 0 0 1-.884 0l-5-5a.625.625 0 0 1 0-.884"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgChevronDown
