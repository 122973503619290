import { useUser } from "@clerk/nextjs"
import { useEffect } from "react"
import { refreshClaimedApps, refreshUserUsername } from "../actions"

export const UserObserver = () => {
  const { isSignedIn, user } = useUser()

  // invalidate the profile cache when the user's username or profile picture change
  useEffect(() => {
    if (!user?.username) return
    refreshUserUsername(user?.username).then()
  }, [user?.username, user?.imageUrl])

  useEffect(() => {
    if (!isSignedIn) return
    refreshClaimedApps().then()
  }, [isSignedIn])

  return null
}
