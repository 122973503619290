import * as React from "react"
import type { SVGProps } from "react"
const SvgLogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M4.167 3.125a1.04 1.04 0 0 0-1.042 1.042v11.666a1.04 1.04 0 0 0 1.042 1.042H7.5a.625.625 0 1 1 0 1.25H4.167a2.29 2.29 0 0 1-2.292-2.292V4.167a2.29 2.29 0 0 1 2.292-2.292H7.5a.625.625 0 1 1 0 1.25z" />
    <path d="M12.891 5.391a.625.625 0 0 1 .884 0l4.167 4.167a.62.62 0 0 1 0 .884l-4.167 4.167a.625.625 0 1 1-.884-.884l3.1-3.1H7.5a.625.625 0 1 1 0-1.25h8.491l-3.1-3.1a.625.625 0 0 1 0-.884" />
  </svg>
)
export default SvgLogOut
