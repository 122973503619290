"use client"

import { BaseModalStoreType } from "@store-platform/types"
import { create } from "zustand"

export const useMoreFiltersModal = create<BaseModalStoreType<null>>((set) => ({
  payload: null,
  isOpen: false,
  open: () => set({ isOpen: true }),
  onOpenChange: (open: boolean) => set({ isOpen: open }),
}))
