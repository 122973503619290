import * as React from "react"
import type { SVGProps } from "react"
const SvgArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.609 5.391a.625.625 0 0 1 0 .884L6.275 14.61a.625.625 0 1 1-.884-.884l8.334-8.334a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M5.208 5.833c0-.345.28-.625.625-.625h8.334c.345 0 .625.28.625.625v8.334a.625.625 0 0 1-1.25 0V6.458H5.833a.625.625 0 0 1-.625-.625"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgArrowUpRight
