"use client"
import {
  MobileHomeFooter,
  MobileListingFooter,
} from "@store-platform/ui/common"
import { useParams, usePathname } from "next/navigation"
import { useEffect, useState } from "react"
import { getListing } from "../../actions"
import { Listing } from "@store-platform/types"
import { formatListing } from "@store-platform/utils"
import { useDevAccessModal } from "../../(developer)/developer/_hooks/useDevAccessModal"
import { useRole } from "@store-platform/backend/api-client"

export const MobileBottomNavbar = () => {
  const params = useParams()
  const pathname = usePathname()
  const { open: openDevAccessModal } = useDevAccessModal()
  const { isUser, isDev } = useRole()

  const [listing, setListing] = useState<Listing | undefined>()
  useEffect(() => {
    function fetchListing() {
      if (params?.listingId && typeof params?.listingId === "string") {
        getListing(params?.listingId).then((listing) => {
          if (listing) setListing(formatListing(listing))
        })
      }
    }

    fetchListing()
  }, [params?.listingId])

  if (!pathname.startsWith("/developer") && params?.listingId) {
    return <MobileListingFooter listing={listing} />
  }

  return (
    <MobileHomeFooter
      isDeveloper={isDev}
      openDevAccessModal={openDevAccessModal}
    />
  )
}
