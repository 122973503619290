import * as React from "react"
import type { SVGProps } from "react"
const SvgCog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 1.042c.345 0 .625.28.625.625v1.068c.86.074 1.693.3 2.466.66l.535-.924a.625.625 0 1 1 1.082.625l-.535.925a7.291 7.291 0 0 1 1.806 1.806l.925-.535a.625.625 0 0 1 .625 1.082l-.925.535c.362.773.587 1.607.66 2.466h1.07a.625.625 0 1 1 0 1.25h-1.07c-.073.86-.298 1.693-.66 2.466l.925.535a.625.625 0 1 1-.625 1.082l-.925-.535a7.294 7.294 0 0 1-1.806 1.806l.535.925a.625.625 0 0 1-1.082.625l-.535-.925a7.29 7.29 0 0 1-2.466.66v1.07a.625.625 0 1 1-1.25 0v-1.07a7.29 7.29 0 0 1-2.466-.66l-.534.925a.625.625 0 1 1-1.083-.625l.534-.925a7.288 7.288 0 0 1-1.805-1.806l-.925.535a.625.625 0 0 1-.625-1.082l.925-.535a7.29 7.29 0 0 1-.66-2.466h-1.07a.625.625 0 1 1 0-1.25h1.07c.073-.86.298-1.693.66-2.466l-.925-.535a.625.625 0 1 1 .625-1.082l.925.535A7.291 7.291 0 0 1 5.826 4.02l-.534-.925a.625.625 0 1 1 1.083-.625l.534.925a7.291 7.291 0 0 1 2.466-.66v-1.07c0-.345.28-.624.625-.624M7.536 15.517a6.04 6.04 0 0 0 8.473-4.892h-3.804a2.292 2.292 0 0 1-2.767 1.597zm.82-3.92-1.902 3.295A6.049 6.049 0 0 1 3.958 10a6.042 6.042 0 0 1 2.496-4.891l1.902 3.295a2.292 2.292 0 0 0 0 3.192m1.152-.679a.603.603 0 0 0-.059-.034A1.045 1.045 0 0 1 8.96 10a1.042 1.042 0 1 1 .55.918m4.764-5.19a6.042 6.042 0 0 1 1.737 3.647h-3.804a2.292 2.292 0 0 0-2.767-1.597L7.536 4.483a6.04 6.04 0 0 1 6.736 1.245"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCog
