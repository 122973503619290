import * as React from "react"
import type { SVGProps } from "react"
const SvgClaimedVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16m2.824-10.858a.6.6 0 0 1 0 .849l-4.4 4.4a.6.6 0 0 1-.848 0l-2-2a.6.6 0 0 1 .848-.849L8 11.118l3.976-3.976a.6.6 0 0 1 .848 0m1.6 2.267a.6.6 0 0 1 0 .848l-3 3a.6.6 0 0 1-.848 0l-.6-.6a.6.6 0 1 1 .848-.848l.176.176 2.576-2.576a.6.6 0 0 1 .848 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgClaimedVerified
