"use client"

import { ClaimedApp } from "@store-platform/types"
import { useDevAccessModal } from "../../(developer)/developer/_hooks/useDevAccessModal"
import { Button } from "@store-platform/ui/common"
import { useRole } from "@store-platform/backend/api-client"
import { useUser } from "@clerk/nextjs"

type SidebarCtaProps = {
  app?: ClaimedApp
}

export function SidebarCta(props: SidebarCtaProps) {
  const { isDev } = useRole()
  const { user } = useUser()
  const plan = user?.publicMetadata.plan
  const kycVerified = user?.publicMetadata.kyc

  switch (true) {
    case !user:
      return <CtaBlock header="Have an app?" action="Get Listed" href="/list" />
    case !isDev:
      return <DevAccessCta header="Have an app?" action="Get Dev Access" />
    case isDev && plan === "free":
      return (
        <CtaBlock
          header="Enhance your listing"
          action="Get Verified"
          href="/settings/plan"
        />
      )
    case isDev && plan !== "free" && !kycVerified:
      return (
        <CtaBlock
          header="Complete Verification"
          action="Verify Identity"
          href="/settings/verified"
        />
      )
    case isDev && plan !== "free" && kycVerified && !!props.app:
      return (
        <CtaBlock
          header="Boost your listing"
          action="Get Verified Reviews"
          href={`/developer/${props.app.id}/verified-reviews`}
        />
      )
    default:
      return null
  }
}

type CtaBlockProps = {
  header: string
  action: string
  href: string
}

function CtaBlock(props: CtaBlockProps) {
  return (
    <div className="bg-blue-100 hidden lg:flex items-center justify-center w-full max-h-32 rounded-lg relative">
      <div className="flex flex-col items-center justify-center p-3 space-y-2 w-full">
        <p className="text-blue-500 text-md">{props.header}</p>
        <Button variant="default" href={props.href} asLink className="w-full">
          {props.action}
        </Button>
      </div>
    </div>
  )
}

function DevAccessCta(props: Omit<CtaBlockProps, "href">) {
  const { open } = useDevAccessModal()

  return (
    <div className="bg-blue-100 hidden lg:flex items-center justify-center w-full max-h-32 rounded-lg relative">
      <div className="flex flex-col items-center justify-center p-3 space-y-2 w-full">
        <p className="text-blue-500 text-md">{props.header}</p>
        <Button variant="default" onClick={() => open()} className="w-full">
          {props.action}
        </Button>
      </div>
    </div>
  )
}
