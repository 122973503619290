import * as React from "react"
import type { SVGProps } from "react"
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10.625 4.167a.625.625 0 1 0-1.25 0v5.208H4.167a.625.625 0 1 0 0 1.25h5.208v5.208a.625.625 0 1 0 1.25 0v-5.208h5.208a.625.625 0 1 0 0-1.25h-5.208z" />
  </svg>
)
export default SvgPlus
