import * as React from "react"
import type { SVGProps } from "react"
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.542 10c0-.345.28-.625.625-.625h11.666a.625.625 0 1 1 0 1.25H4.167A.625.625 0 0 1 3.542 10"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10.442 3.725a.625.625 0 0 1 0 .884L5.05 10l5.39 5.391a.625.625 0 1 1-.883.884l-5.833-5.833a.625.625 0 0 1 0-.884l5.833-5.833a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgArrowLeft
