"use client"
import { getBestIcon, preventClickBubbleUp } from "@store-platform/utils"
import { SVGProps, useState } from "react"
import { useAddToList } from "../../hooks"
import { CreateList } from "./CreateList"
import { ListSelector } from "./ListSelector"
import {
  ResponsiveModal,
  ResponsiveModalContent,
} from "../ResponsiveModal/ResponsiveModal"
import { AppIcon } from "../AppIcon/AppIcon"
import { SvgHeartOutline, SvgStar } from "@store-platform/ui/icons"

type tabs = "listSelector" | "createList"

const headings: {
  [key in tabs]: {
    icon: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
    heading: string
    subheading: (listingInfo: JSX.Element) => JSX.Element
  }
} = {
  listSelector: {
    icon: SvgHeartOutline,
    heading: "Add to a list",
    subheading: (listingInfo) => (
      <>Add {listingInfo} to one or more lists below:</>
    ),
  },
  createList: {
    icon: SvgStar,
    heading: "Give your new list a name",
    subheading: (listingInfo) => <>Add {listingInfo} to a new list:</>,
  },
}

export function AddToListModal() {
  const [tab, setTab] = useState<tabs>("listSelector")
  const {
    isOpen,
    onOpenChange,
    payload: { listing },
  } = useAddToList()

  const header = headings[tab]

  return (
    <ResponsiveModal
      open={isOpen}
      onOpenChange={(open) => {
        onOpenChange(open)
        setTimeout(() => {
          if (!open) setTab("listSelector")
        }, 300)
      }}
    >
      <ResponsiveModalContent
        className="bg-gray-50 p-5 gap-0 sm:w-[800px]"
        onClick={preventClickBubbleUp}
      >
        <div>
          <div className="max-sm:hidden w-fit p-2 bg-white rounded-full mb-2">
            <header.icon className="h-8 w-8" />
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold leading-none tracking-tight">
            {header.heading}
          </h2>
          <span className="flex flex-row items-center gap-x-1 whitespace-nowrap text-gray-700 font-light">
            {header.subheading(
              <>
                <AppIcon
                  imgUrl={getBestIcon(listing?.icons)?.src}
                  size={48}
                  className="w-5 h-5"
                />
                <p className="font-semibold">{listing?.name}</p>
              </>,
            )}
          </span>
        </div>
        {
          {
            createList: (
              <CreateList setListSelector={() => setTab("listSelector")} />
            ),
            listSelector: (
              <ListSelector setCreateList={() => setTab("createList")} />
            ),
          }[tab]
        }
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
