import * as React from "react"
import type { SVGProps } from "react"
const SvgPwa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M6.549 8.875c.099.1.149.234.149.402 0 .17-.044.303-.131.402-.096.11-.273.166-.53.166h-.396v-1.12h.399c.24 0 .41.05.509.15" />
    <path
      fillRule="evenodd"
      d="M11.418 2.19a1.689 1.689 0 0 0-2.836 0 1.689 1.689 0 0 1-2.025.659 1.689 1.689 0 0 0-2.294 1.667A1.689 1.689 0 0 1 3.01 6.238a1.689 1.689 0 0 0-.876 2.697c.504.62.504 1.51 0 2.13a1.689 1.689 0 0 0 .876 2.697 1.689 1.689 0 0 1 1.252 1.723 1.689 1.689 0 0 0 2.294 1.666 1.689 1.689 0 0 1 2.025.658 1.689 1.689 0 0 0 2.836 0 1.689 1.689 0 0 1 2.025-.658 1.689 1.689 0 0 0 2.294-1.666 1.689 1.689 0 0 1 1.252-1.723 1.689 1.689 0 0 0 .876-2.697 1.689 1.689 0 0 1 0-2.13 1.689 1.689 0 0 0-.876-2.697 1.689 1.689 0 0 1-1.252-1.722 1.689 1.689 0 0 0-2.294-1.667 1.689 1.689 0 0 1-2.025-.658M5.64 10.654h.686c.207 0 .392-.023.555-.07l.673-2.074a1.22 1.22 0 0 0-.13-.17c-.254-.281-.627-.422-1.117-.422H4.583v4.166h1.058zm7.78-2.736-1.68 4.166h-1.1L9.887 9.67l-.793 2.414H7.997l-.569-.976.56-1.725.62 1.227.877-2.692h.856l.817 2.692 1.149-2.692zm-.373 2.612-.32.808h1.497l.268.746h1.157l-1.57-4.166-.547 1.385.438 1.227z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgPwa
