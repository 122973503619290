"use client"

import { Suspense, useEffect, useState } from "react"
import { LaunchModal } from "../modals/LaunchModal"
import { ImageUploadModal, MoreFiltersModal, ReviewsModal } from "@store-platform/ui/common"
import { GenericAlertDialog } from "../modals/AlertDialog"
import { ScreenWidthObserver } from "./ScreenWidthObserver"
import { InstalledObserver } from "./InstalledObserver"
import { AddToListProvider } from "./AddToListProvider"
import { DeviceTypeObserver } from "./DeviceTypeObserver"
import { AttributeStateProvider } from "./AttributeStateProvider"
import { UserIdentityObserver } from "@store-platform/backend/api-client"
import { AuthModal } from "../modals/AuthModal"
import { UserObserver } from "../modals/UserObserver"
import { AccountSettingsModal } from "../modals/AccountSettingsModal"
import { ShareLinkObserver } from "./ShareLinkObserver"

/**
 * Consolidate modals used in multiple places in the app
 */
export const GlobalProvider = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // This ensures that the modals are only mounted & rendered on the client
  if (!isMounted) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <UserIdentityObserver />
      <AccountSettingsModal />
      <AuthModal />
      <UserObserver />
      <LaunchModal />
      <GenericAlertDialog />
      <ReviewsModal />
      <ImageUploadModal />
      <ScreenWidthObserver />
      <InstalledObserver />
      <AddToListProvider />
      <DeviceTypeObserver />
      <MoreFiltersModal />
      <AttributeStateProvider />
      <ShareLinkObserver />
    </Suspense>
  )
}
