"use client"

import {
  Button,
  FormFieldInput,
  FormFieldTextArea,
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalTitle,
} from "@store-platform/ui/common"
import { useDevAccessModal } from "../_hooks/useDevAccessModal"
import { SvgHammer } from "@store-platform/ui/icons"
import { z } from "zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Form from "@radix-ui/react-form"
import { getDevAccess } from "../actions"
import { toast } from "sonner"
import React, { useEffect, useState } from "react"
import { useAnalytics } from "@store-platform/backend/api-client"
import { useUser } from "@clerk/nextjs"
import { formErrorClassName } from "@store-platform/utils"

const formSchema = z.object({
  email: z
    .string({
      required_error: "Email Address is required",
    })
    .email("Invalid email")
    .optional(),
  applink: z
    .string({
      required_error: "Your app URL is required",
    })
    .url(),
  about: z
    .string({
      required_error: "A quick introduction is required",
    })
    .min(1, "A quick introduction is required"),
})

type FormSchemaType = z.infer<typeof formSchema>

type DevAccessModalProps = {
  email: string
}

export function DevAccessModal(props: DevAccessModalProps) {
  const { isOpen, onOpenChange } = useDevAccessModal()
  const initialValues = { email: props.email, applink: "https://", about: "" }
  const [error, setError] = useState<string | undefined>(undefined)
  const { trackerReady, trackEvent } = useAnalytics()
  const { user } = useUser()

  useEffect(() => {
    if (!isOpen || !trackerReady) return
    trackEvent({
      obj: "Dev",
      action: "Initiated Access Request",
      properties: {
        email: props.email,
      },
    })
  }, [isOpen, props.email, trackEvent, trackerReady])

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  })

  const processForm: SubmitHandler<FormSchemaType> = async (data) => {
    const { error } = await getDevAccess({
      email: props.email,
      applink: data.applink,
      about: data.about,
    })
    if (!error) {
      setError(undefined)
      form.reset(initialValues)
      onOpenChange(false)
      toast.info("Dev Access Requested", {
        description: "Hang tight, we'll let you know when you've been approved",
        duration: 5000,
        dismissible: false,
        onAutoClose: () => {
          user?.reload()
          toast.info("Dev Access Granted", {
            description: "You now have access to your developer panel",
            duration: 5000,
          })
        },
      })
      return
    }

    setError(error)
  }

  return (
    <ResponsiveModal open={isOpen} onOpenChange={onOpenChange}>
      <ResponsiveModalContent className="flex flex-col items-center justify-center sm:max-w-[460px] gap-2">
        <SvgHammer className="w-7 h-7" />
        <ResponsiveModalTitle
          className="text-xl font-semibold leading-6 text-gray-900"
          asChild
        >
          <h3>Request Developer Account</h3>
        </ResponsiveModalTitle>
        <p className="my-2 text-sm text-gray-500 text-center">
          Request developer access to add, claim, and manage your listings on
          store.app!
        </p>
        <Form.Root onSubmit={form.handleSubmit(processForm)} className="w-full">
          <FormFieldInput
            registry={form.register("email", {
              disabled: true,
              // TODO: what is this
              value: "tyiop",
            })}
            error={form.formState.errors.email?.message}
            label="Email Address"
            inputHtmlAttributes={{
              autoComplete: "off",
              autoCapitalize: "off",
            }}
          />
          <FormFieldInput
            registry={form.register("applink")}
            error={form.formState.errors.applink?.message}
            label="App URL"
            inputHtmlAttributes={{
              autoComplete: "url",
              autoCapitalize: "off",
            }}
          />
          <FormFieldTextArea
            registry={form.register("about")}
            error={form.formState.errors.about?.message}
            label="Tell us about yourself"
            caption={{ text: "Links to socials, Github, or others" }}
          />

          <Form.Submit asChild>
            <Button
              variant="default"
              className="w-full mt-3"
              disabled={form.formState.isSubmitting}
            >
              Submit
            </Button>
          </Form.Submit>
          {!!error && <span className={formErrorClassName}>{error}</span>}
        </Form.Root>
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
