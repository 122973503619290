"use client"

import { SignedIn, SignedOut, UserButton } from "@clerk/nextjs"
import { Button, useAuthModal } from "@store-platform/ui/common"
import { defaultUserProfileElementStyling } from "@store-platform/utils"

export const NavUserButton = () => {
  const { open } = useAuthModal()
  return (
    <>
      <SignedOut>
        <Button
          onClick={() => open({ redirectUrl: undefined })}
          variant="default"
        >
          Login
        </Button>
      </SignedOut>
      <SignedIn>
        <UserButton
          userProfileMode={"modal"}
          userProfileProps={{
            appearance: {
              elements: defaultUserProfileElementStyling,
            },
          }}
        />
      </SignedIn>
    </>
  )
}
