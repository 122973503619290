"use client"
import { EnrichedList } from "@store-platform/types"
import { Button } from "../Button/Button"
import { isAlphanumeric } from "@store-platform/utils"
import { useMemo } from "react"
import { selectLists, useAddToList } from "../../hooks"
import * as Form from "@radix-ui/react-form"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormFieldInput } from "../FormFieldInput/FormFieldInput"
import { useShallow } from "zustand/react/shallow"

const isUniqueListName = (lists: EnrichedList[]) => (name: string) =>
  !lists.some((list) => list.name === name)

type FormSchemaType = { name: string }

export const CreateList = ({
  setListSelector,
}: {
  setListSelector: () => void
}) => {
  const { createList } = useAddToList()
  const lists = useAddToList(useShallow(selectLists))

  const formSchema = useMemo(
    () =>
      z.object({
        name: z
          .string({
            required_error: "List name is required",
          })
          .refine(isAlphanumeric, {
            message: "List name must be alphanumeric",
          })
          .refine(isUniqueListName(lists), {
            message: "List name must be unique",
          }),
      }),
    [lists],
  )

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  })

  const processForm: SubmitHandler<FormSchemaType> = async (data) => {
    const { message } = (await createList(data.name)) || {}

    if (message) {
      form.setError("root", { message })
    } else {
      form.reset()
      setListSelector()
    }
  }

  return (
    <Form.Root
      onSubmit={form.handleSubmit(processForm)}
      className="flex flex-col justify-between flex-1"
    >
      <div className="relative mx-[2px] mt-3">
        <FormFieldInput
          label="List Name"
          registry={form.register("name")}
          error={form.formState.errors.name?.message}
          inputHtmlAttributes={{
            className: "bg-white",
          }}
        />
      </div>
      <div className="mt-4 flex justify-between gap-x-2">
        <Button
          variant="neutral"
          type="button"
          className="basis-1/2"
          onClick={setListSelector}
        >
          Cancel
        </Button>
        <Button
          variant={form.formState.isSubmitting ? "ghost" : "default"}
          disabled={form.formState.isSubmitting}
          onClick={form.handleSubmit(processForm)}
          className="basis-1/2"
        >
          Create & Add
        </Button>
      </div>
    </Form.Root>
  )
}
