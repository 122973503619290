"use client"

import * as Form from "@radix-ui/react-form"
import { clsx } from "clsx"
import React, { InputHTMLAttributes, MouseEvent, ReactElement } from "react"
import { UseFormRegisterReturn } from "react-hook-form"
import { SvgInfo } from "@store-platform/ui/icons"
import { Button } from "../Button/Button"
import { cn, formErrorClassName } from "@store-platform/utils"

export interface FormFieldTextAreaProps {
  label: string
  caption?: {
    text: string
    icon?: ReactElement | false
    action?: {
      text: string
      onClick: (event?: MouseEvent<HTMLButtonElement>) => void
    }
  }
  error?: string
  registry: UseFormRegisterReturn
  maxLength?: number
  value?: string
  icon?: React.ReactElement
  inputHtmlAttributes?: InputHTMLAttributes<HTMLTextAreaElement>
}

export function FormFieldTextArea(props: FormFieldTextAreaProps) {
  return (
    <Form.Field className="relative mb-2" name={props.registry.name}>
      <div
        className={cn(
          "bg-neutral-100 pt-7 px-2.5 rounded-lg group",
          props.inputHtmlAttributes?.className,
          props.error &&
            "[&:not(:focus-within)]:ring-1 [&:not(:focus-within)]:ring-red-700",
        )}
      >
        <Form.Control asChild>
          <textarea
            {...props.inputHtmlAttributes}
            className={cn(
              "block bg-inherit peer h-36 w-full rounded-lg border-none pb-0 appearance-none scrollbar-thin",
              "focus:outline-none focus:ring-0",
              props.registry.disabled ? "text-gray-600" : "text-gray-900",
            )}
            placeholder={" "}
            {...props.registry}
            maxLength={props.maxLength}
          />
        </Form.Control>
      </div>
      <Form.Label
        className={clsx(
          "absolute duration-300 transform -translate-y-4 scale-75 top-5 origin-[0] start-2.5 text-gray-700",
          "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-gray-500",
          "peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-gray-700",
        )}
      >
        {props.label}
      </Form.Label>
      {(!!props.maxLength || props.icon) && (
        <span className="absolute right-3 top-2.5 text-gray-700 text-xs flex items-center gap-2 h-[20px]">
          {props.icon}
          {!!props.maxLength && (
            <span>
              {props.value?.length || 0}/{props.maxLength}
            </span>
          )}
        </span>
      )}
      {!!props.error && (
        <Form.Message className={formErrorClassName}>
          {props.error}
        </Form.Message>
      )}
      {!props.error && !!props.caption && (
        <p className="text-xs opacity-[0.8] text-gray-500 grid grid-cols-[1fr_auto] gap-1 justify-between items-start h-fit pt-0.5">
          <span className="flex gap-1 truncate">
            {props.caption.icon !== false &&
              (props.caption.icon ?? (
                <SvgInfo className="h-4 w-4 min-w-4" />
              ))}{" "}
            <span className="truncate">{props.caption.text}</span>
          </span>
          {!!props.caption.action && (
            <Button
              variant="naked"
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                if (!props.caption?.action) return
                props.caption.action.onClick(event)
              }}
              className="text-blue-500 hover:underline text-nowrap text-right"
            >
              {props.caption.action.text}
            </Button>
          )}
        </p>
      )}
    </Form.Field>
  )
}
