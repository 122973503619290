/* eslint-disable-next-line */
import { cn } from "@store-platform/utils"
import { ReactElement } from "react"

export interface InfoBoxProps {
  message: string | ReactElement
  type?: "info" | "success" | "warning" | "error"
  className?: {
    box?: string
    content?: string
  }
  button?: ReactElement
}

export function InfoBox(props: InfoBoxProps) {
  return (
    <div
      className={cn(
        "rounded-md",
        {
          "bg-green-100": props.type === "success",
          "bg-blue-100": props.type === "info" || props.type === undefined,
          "bg-yellow-100": props.type === "warning",
          "bg-red-100": props.type === "error",
        },
        "px-8 py-4",
        props.className?.box,
      )}
    >
      <div
        className={cn(
          "flex justify-between items-center max-sm:flex-col",
          props.className?.content,
        )}
      >
        <div className="py-2 pr-4">{props.message}</div>
        {props.button}
      </div>
    </div>
  )
}
