import * as React from "react"
import type { SVGProps } from "react"
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.558 7.058a.625.625 0 0 1 .884 0l5 5a.625.625 0 1 1-.884.884L10 8.384l-4.558 4.558a.625.625 0 1 1-.884-.884z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgChevronUp
