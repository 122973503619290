"use client"

import * as Form from "@radix-ui/react-form"
import { useForm } from "react-hook-form"
import { useCallback, useEffect } from "react"
import { FormFieldFile } from "./FormFieldFile"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const formSchema = z.object({
  file: z.custom<File>((v) => v instanceof File, {
    message: "A file is required",
  }),
})

type FormSchemaType = z.infer<typeof formSchema>

export interface FormFieldFileAutoSaveProps {
  label: string
  action: (value: File) => unknown
  accept?: string[]
  error?: string
}

export function FormFieldFileAutoSave(props: FormFieldFileAutoSaveProps) {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit = useCallback(
    async (data: any) => {
      await props.action(data.value)
    },
    [props],
  )
  useEffect(() => {
    form.register("file", {
      onChange: (e) => {
        props.action(e.target.value)
      },
    })
  }, [form, props])

  return (
    <Form.Root onSubmit={form.handleSubmit(onSubmit)}>
      <FormFieldFile
        name="file"
        control={form.control}
        label={props.label}
        accept={props.accept}
      />
    </Form.Root>
  )
}
