"use client"

import { usePathname } from "next/navigation"
import { cn } from "@store-platform/utils"
import { MouseEventHandler, ReactNode } from "react"
import { useMobileDrawerMenu } from "../../../../_components/hooks/useMobileDrawerMenu"
import { Button, Link, LinkProps } from "@store-platform/ui/common"

export type SidebarItemProps = Pick<
  LinkProps,
  "href" | "asExternal" | "prefetch"
> & {
  label: string
  onClick?: MouseEventHandler
  pattern?: RegExp
  icon: ReactNode
  mobileDrawer?: boolean
  align?: "start" | "center"
  cta?: boolean
  className?: string
  asButton?: boolean
}

export const SidebarItem = (props: SidebarItemProps) => {
  const pathname = usePathname()
  const { toggle: toggleDrawer } = useMobileDrawerMenu()

  const active = !props.pattern
    ? pathname === props.href
    : !!pathname.match(props.pattern)

  const classes = cn(
    "without-ring group flex items-center justify-center rounded-md p-2 text-sm focus:outline-none focus:ring-0 border-1 border-transparent sm:max-lg:justify-center lg:gap-x-2 w-full",
    {
      "bg-blue-50 hover:bg-opacity-70 text-blue-500": active,
      "hover:bg-gray-50 text-gray-700": !active,
      "bg-blue-500 text-white hover:bg-blue-400": props?.cta,
      "pl-5 flex gap-3 justify-start": !!props.mobileDrawer,
      "justify-start": props.align === "start" || !props.align,
      "justify-center": props.align === "center",
    },
    props.className,
  )

  const onClick: MouseEventHandler = (event) => {
    !!props.onClick && props.onClick(event)
    !!props.mobileDrawer && toggleDrawer(false)
  }

  const content = (
    <>
      {props.icon}
      <p
        className={cn("truncate", {
          "hidden lg:flex": !props.mobileDrawer,
        })}
      >
        <span className="truncate">{props.label}</span>
      </p>
    </>
  )

  if (props.asButton) {
    return (
      <Button
        variant="naked"
        className={classes}
        onClick={(event) => onClick(event)}
      >
        {content}
      </Button>
    )
  }

  return (
    <Link
      variant="naked"
      href={props.href}
      asExternal={props.asExternal}
      prefetch={props.prefetch}
      className={classes}
      onClick={(event) => {
        !!props.onClick && props.onClick(event)
        !!props.mobileDrawer && toggleDrawer(false)
      }}
    >
      {content}
    </Link>
  )
}
