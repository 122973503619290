import * as React from "react"
import type { SVGProps } from "react"
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.167 1.875a2.292 2.292 0 0 0-2.292 2.292v2.5a2.292 2.292 0 0 0 2.292 2.291h2.5a2.292 2.292 0 0 0 2.291-2.291v-2.5a2.292 2.292 0 0 0-2.291-2.292zM3.125 4.167c0-.576.466-1.042 1.042-1.042h2.5c.575 0 1.041.466 1.041 1.042v2.5c0 .575-.466 1.041-1.041 1.041h-2.5a1.042 1.042 0 0 1-1.042-1.041zM4.167 11.042a2.292 2.292 0 0 0-2.292 2.291v2.5a2.292 2.292 0 0 0 2.292 2.292h2.5a2.292 2.292 0 0 0 2.291-2.292v-2.5a2.292 2.292 0 0 0-2.291-2.291zm-1.042 2.291c0-.575.466-1.041 1.042-1.041h2.5c.575 0 1.041.466 1.041 1.041v2.5c0 .576-.466 1.042-1.041 1.042h-2.5a1.042 1.042 0 0 1-1.042-1.042z"
      clipRule="evenodd"
    />
    <path d="M11.042 3.333c0-.345.28-.625.625-.625H17.5a.625.625 0 1 1 0 1.25h-5.833a.625.625 0 0 1-.625-.625M11.667 6.875a.625.625 0 1 0 0 1.25H17.5a.625.625 0 1 0 0-1.25zM11.042 12.5c0-.345.28-.625.625-.625H17.5a.625.625 0 1 1 0 1.25h-5.833a.625.625 0 0 1-.625-.625M11.667 16.042a.625.625 0 1 0 0 1.25H17.5a.625.625 0 0 0 0-1.25z" />
  </svg>
)
export default SvgList
