import { cva, VariantProps } from "class-variance-authority"
import * as React from "react"
import { cn } from "@store-platform/utils"

export type badgeVariant =
  | "default"
  | "secondary"
  | "outline"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "neutral"
  | "naked"
  | "dotted"

export type BadgeSize = "sm" | "auto" | "lg"

interface BadgeVariants {
  variants: {
    variant: {
      [variant in badgeVariant]: string
    }
    size: {
      [size in BadgeSize]: string
    }
  }
  defaultVariants: {
    variant: badgeVariant
    size: BadgeSize
  }
}

const badgeVariantConfig: BadgeVariants = {
  variants: {
    variant: {
      default: "border-blue-50 bg-blue-50 text-blue-500 hover:bg-blue-50/80",
      secondary:
        "border-gray-100 bg-gray-100 text-gray-800 hover:bg-secondary/80",
      outline: "text-gray-800 border-gray-300",
      info: "border-blue-50 bg-blue-50 text-blue-500 hover:bg-blue-50/80",
      success: "border-teal-100 bg-teal-100 text-teal-600 hover:bg-teal-100/80",
      warning:
        "border-yellow-50 bg-yellow-50 text-yellow-500 hover:bg-yellow-50/80",
      danger: "border-red-50 bg-red-50 text-red-500 hover:bg-red-50/80",
      neutral: "border-gray-50 bg-gray-50 text-gray-500 hover:bg-gray-50/80",
      naked: "border-transparent p-0",
      dotted: "border-dotted",
    },
    size: {
      sm: "h-8 px-4",
      auto: "",
      lg: "h-12",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "auto",
  },
}

const badgeVariants = cva(
  "inline-flex gap-1 items-center rounded-md border px-2.5 py-0.5 text-xs font-normal whitespace-nowrap transition-colors focus:outline-none focus:ring-0 w-fit",
  badgeVariantConfig,
)

type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants> & {
    className?: string
    rounded?: boolean
  }

const Badge: React.FC<BadgeProps> = React.forwardRef<
  HTMLDivElement,
  BadgeProps
>(({ className, variant, size, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        badgeVariants({ variant, size }),
        { "rounded-full px-3": props.rounded },
        className,
      )}
      {...props}
    />
  )
})

Badge.displayName = "Badge"

export { Badge, badgeVariants }
