import * as React from "react"
import type { SVGProps } from "react"
const SvgBrowser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M4.375 6.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25M6.667 5.625a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0M7.708 6.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25" />
    <path
      fillRule="evenodd"
      d="M1.458 5.333a2.625 2.625 0 0 1 2.625-2.625h11.834a2.625 2.625 0 0 1 2.625 2.625v8.5a2.625 2.625 0 0 1-2.625 2.625H4.083a2.625 2.625 0 0 1-2.625-2.625zm2.625-1.375c-.76 0-1.375.616-1.375 1.375v1.75h14.584v-1.75c0-.76-.616-1.375-1.375-1.375zm-1.375 9.875v-5.5h14.584v5.5c0 .76-.616 1.375-1.375 1.375H4.083c-.76 0-1.375-.615-1.375-1.375"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBrowser
