"use client"
import { Review, Testimonial } from "@store-platform/types"
import { cn, getBestIcon, toRelative } from "@store-platform/utils"
import Link from "next/link"
import { ReactNode, useEffect, useRef, useState } from "react"
import { SvgCheck, SvgPin, SvgPinSolid } from "@store-platform/ui/icons"
import { StarRating } from "../StarRating/StarRating"
import { ProfilePicture } from "../ProfilePicture/ProfilePicture"
import { AppIcon } from "../AppIcon/AppIcon"
import { Button } from "../Button/Button"
import {
  ResponsiveModal,
  ResponsiveModalContent,
} from "../ResponsiveModal/ResponsiveModal"
import { Tooltip } from "../Tooltip/Tooltip"

type ReviewCardVariants = "default" | "testimonial"
type ExpandStyle = "default" | "modal"

type DefaultReviewCardProps = {
  review: Review
  showAppIcon?: boolean
  className?: string
  expandStyle: ExpandStyle
  expanded?: boolean
  setOpenModal?: (open: boolean) => void
}

const DefaultReviewCard = ({
  review,
  showAppIcon = false,
  className,
  expandStyle,
  expanded: _expanded,
  setOpenModal,
}: DefaultReviewCardProps) => {
  const authorName = review?.authorName
  const updatedAt = review?.updatedAt
  const verified = review?.verified
  const isAnonUser = !showAppIcon && !review?.username

  const [expanded, setExpanded] = useState(_expanded)
  const [isOverflow, setIsOverflow] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const content = textRef.current
    const contentScrollHeight = content?.scrollHeight || 0
    const contentClientHeight = content?.clientHeight || 0
    if (content) {
      setIsOverflow(contentScrollHeight > contentClientHeight)
    }
  }, [])

  const handleExpandClick = () => {
    if (expandStyle === "modal" && setOpenModal) {
      setOpenModal(true)
    } else {
      setExpanded(!expanded)
    }
  }

  const handleNameClick = (event: any) => {
    event.stopPropagation()
  }

  const ConditionalLink = isAnonUser ? "div" : Link

  const appIcon = getBestIcon(review?.listing?.icons)?.src

  return (
    <div
      className={cn(
        "relative col-span-1 rounded-md bg-gray-50 p-4",
        { "min-h-[180px]": !showAppIcon },
        className,
      )}
    >
      <div
        className={cn("flex items-center", {
          "cursor-pointer": !isAnonUser,
        })}
      >
        {showAppIcon && appIcon ? (
          <AppIcon imgUrl={appIcon} size={48} className="w-12" />
        ) : (
          <ConditionalLink
            href={
              showAppIcon
                ? `/${review?.listing?.id}`
                : `/user/${review?.username}`
            }
            onClick={handleNameClick}
          >
            <ProfilePicture
              imgUrl={review.authorProfilePicture}
              fallback={review.authorName}
              size="s"
            />
          </ConditionalLink>
        )}
        <div className="ml-2 w-full">
          <ConditionalLink
            className={cn(
              "flex w-fit flex-row text-sm font-bold text-gray-900 no-underline text-ellipsis flex-nowrap",
              {
                "hover:underline cursor-pointer": !isAnonUser,
                "max-w-[calc(150px)]": showAppIcon,
              },
            )}
            href={
              showAppIcon
                ? `/${review?.listing?.id}`
                : `/user/${review?.username}`
            }
            onClick={handleNameClick}
          >
            {showAppIcon ? review?.listing?.name : authorName}
          </ConditionalLink>

          <div className="flex flex-row items-center justify-between sm:flex-wrap sm:items-center cursor-default">
            <StarRating rating={review.rating} />
            <span className="text-xs text-gray-500">
              {toRelative(new Date(updatedAt || ""))}
            </span>
          </div>

          <p className="sr-only">{review.rating} out of 5 stars</p>
        </div>
      </div>

      <p
        ref={textRef}
        className={cn(
          "mt-2 text-base text-gray-600 text-left",
          expanded ? "min-h-fit" : "max-h-[72px] line-clamp-3",
        )}
      >
        {review.content || "..."}
      </p>
      {isOverflow && (
        <div
          className="text-blue-500 hover:text-blue-600 text-right cursor-pointer mt-[8px] text-sm"
          onClick={handleExpandClick}
        >
          See {expanded ? "less" : "more"}
        </div>
      )}
      {verified && (
        <span className="absolute top-4 right-4">
          <VerifiedReviewBadge />
        </span>
      )}
    </div>
  )
}

const DefaultReviewCardWithModal = (props: DefaultReviewCardProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <DefaultReviewCard {...props} setOpenModal={() => setOpen(true)} />
      {props.expandStyle === "modal" && (
        <ResponsiveModal open={open} onOpenChange={setOpen}>
          <ResponsiveModalContent className="!bg-gray-50 sm:max-w-[600px]">
            <DefaultReviewCard {...props} expanded={true} />
          </ResponsiveModalContent>
        </ResponsiveModal>
      )}
    </>
  )
}

const VerifiedReviewBadge = () => (
  <span className="text-blue-500 whitespace-nowrap inline-flex gap-x-1 items-center">
    <SvgCheck className="flex-shrink-0 h-4 w-4 " />
    <p className="max-sm:hidden">Verified Review</p>
    <p className="sm:hidden">Verified</p>
  </span>
)

const PinButton = ({
  pinnable,
  pinned: _pinned,
  ownedByUser,
  toggle,
}: Pick<TestimonialCardProps, "pinnable" | "pinned" | "ownedByUser"> & {
  toggle: () => any
}) => {
  const [pinned, setPinned] = useState(_pinned)
  if (!ownedByUser && !pinned) return null

  const disabled = ownedByUser && !pinnable && !pinned
  const Wrapper = ({ children }: { children: ReactNode }) =>
    disabled ? (
      <Tooltip caption="Maximum of 3 pins allowed">{children}</Tooltip>
    ) : (
      children
    )

  return (
    <Wrapper>
      <Button
        variant="naked"
        className={cn({
          "cursor-auto": !ownedByUser,
          "cursor-not-allowed": disabled,
        })}
        onClick={async () => {
          if (ownedByUser && ((pinnable && !pinned) || pinned)) {
            setPinned(!pinned)
            await toggle()
          }
        }}
      >
        {pinned ? (
          <SvgPinSolid className="h-5 w-5 fill-blue-500" />
        ) : (
          <SvgPin className="h-5 w-5 fill-blue-500" />
        )}
      </Button>
    </Wrapper>
  )
}

type TestimonialCardProps = {
  pinnable: boolean
  username: string
  ownedByUser: boolean
  onTogglePin: (
    reviewId: number,
    pinned: boolean,
    username: string,
  ) => Promise<any>
} & Testimonial

const TestimonialCard = ({
  review,
  pinned,
  pinnable,
  username,
  authorProfilePicture,
  appName,
  authorName,
  authorUsername,
  appIcons,
  onTogglePin,
  ownedByUser,
}: TestimonialCardProps) => {
  return (
    <div className="relative col-span-1 rounded-md bg-gray-50 px-4 pt-3 pb-2 h-fit">
      <div className="grid grid-flow-col justify-between place-items-center">
        <StarRating rating={review.rating} />
        <PinButton
          pinnable={pinnable}
          pinned={pinned}
          ownedByUser={ownedByUser}
          toggle={async () => {
            await onTogglePin(review.id, !pinned, username)
          }}
        />
      </div>

      <div className="mt-4 mb-1 text-base text-gray-600 text-left">
        {review.content}
      </div>

      <div className="grid grid-flow-col justify-start place-items-center gap-x-5">
        <div className="relative">
          <ProfilePicture
            imgUrl={authorProfilePicture}
            fallback={authorName}
            size="s"
          />
          {!!appIcons?.length && (
            <AppIcon
              name={appName}
              className="absolute w-5 h-5 -bottom-1 -right-2 rounded-sm"
              transparentBg
              imgUrl={getBestIcon(appIcons)?.src || ""}
              size={48}
            />
          )}
        </div>
        <div className="grid grid-flow-row place-items-start">
          <span>
            <Link
              href={`/user/${authorUsername}`}
              className="text-blue-500 text-sm"
            >
              {authorName}
            </Link>
            <span className="text-slate-500 text-xs ml-2">
              {toRelative(new Date(review.createdAt || ""))}
            </span>
          </span>
          <span className="text-slate-500 text-sm">For {appName}</span>
        </div>
      </div>
    </div>
  )
}

type ReviewCardProps<T extends ReviewCardVariants> = {
  variant: T
} & (T extends "testimonial" ? TestimonialCardProps : DefaultReviewCardProps)

export function ReviewCard<T extends ReviewCardVariants>(
  props: ReviewCardProps<T>,
) {
  switch (true) {
    case props.variant === "testimonial":
      return <TestimonialCard {...(props as TestimonialCardProps)} />
    default:
      return (
        <DefaultReviewCardWithModal {...(props as DefaultReviewCardProps)} />
      )
  }
}
