"use client"
import { Link, useIsMobile, useListingSearch } from "@store-platform/ui/common"
import { KeyboardEventHandler, useCallback, useState } from "react"
import { SvgSearch } from "@store-platform/ui/icons"
import { Command, CommandInput } from "cmdk"
import { debounce } from "lodash-es"
import { useRouter } from "next/navigation"
import { useRecentSearches } from "../../../../_components/hooks/useRecentSearches"
import { SearchList } from "./SearchList"
import { Listing } from "@store-platform/types"
import {
  Search,
  SearchComponentProps,
  searchPrefix,
} from "../../../../_components/Search"
import { useAnalytics } from "@store-platform/backend/api-client"

export const ListingSearch = () => {
  const isMobile = useIsMobile()
  if (isMobile) return <MobileSearchLink />

  return (
    <search className="flex items-center justify-center">
      <Search indexName="listings" hitsPerPage={4}>
        {(props) => <DesktopSearchComponents {...props} />}
      </Search>
    </search>
  )
}

const findCurrentIndex = (hits: Listing[], selectedValue: string) => {
  return hits.findIndex((item) => item.id === selectedValue)
}

const DesktopSearchComponents = ({
  search,
  query,
  hits,
}: SearchComponentProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string>("")
  const { trackEvent } = useAnalytics()

  const advance = useCallback(() => {
    const currentIndex = findCurrentIndex(hits, selectedValue)
    const nextIndex = (currentIndex + 1) % hits.length
    setSelectedValue(hits[nextIndex].id)
  }, [hits, selectedValue])

  const reverse = useCallback(() => {
    const currentIndex = findCurrentIndex(hits, selectedValue)
    const prevIndex = (currentIndex - 1 + hits.length) % hits.length
    setSelectedValue(hits[prevIndex].id)
  }, [hits, selectedValue])

  const { addRecentSearch } = useRecentSearches()
  const { ref: inputRef } = useListingSearch()
  const router = useRouter()

  // Input element focus state is source of truth for open state of dropdown
  const blurInput = () => inputRef.current?.blur()
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Escape") blurInput()
    if (event.key === "Enter") {
      trackEvent({
        obj: "Search",
        action: "Submitted",
        properties: { query },
      })
      router.push(selectedValue)
      if (selectedValue.includes(searchPrefix)) {
        addRecentSearch(query.replace(searchPrefix, ""))
      }
      blurInput()
    }

    if (event.key === "ArrowDown") advance()
    if (event.key === "ArrowUp") reverse()
  }

  return (
    <Command
      value={selectedValue}
      onValueChange={setSelectedValue}
      className="relative w-full max-w-5xl z-40"
      shouldFilter={false}
    >
      <SvgSearch className="absolute left-4 top-2.5 h-5 w-5 text-gray-500 sm:top-4 pointer-events-none" />
      <CommandInput
        ref={inputRef}
        onKeyDown={handleKeyDown}
        onFocus={debounce(() => {
          setOpen(true)
          trackEvent({
            obj: "Search",
            action: "Initiated",
          })
        }, 150)}
        onBlur={debounce(() => setOpen(false), 150)}
        onValueChange={search}
        className="w-full text-gray-800 appearance-none rounded-lg pl-11 pr-4 focus-visible:focus:border-gray-500 focus-visible:focus:bg-white focus-visible:focus:outline-none focus-visible:focus:ring-1 focus-visible:focus:ring-gray-500 bg-gray-50 py-4 text-sm"
      />
      {open && (
        <div className="absolute w-full max-w-5xl rounded-b-lg bg-white shadow-xl z-40 mt-1">
          <SearchList
            hits={hits}
            query={query}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
      )}
    </Command>
  )
}

const MobileSearchLink = () => (
  <Link
    variant="naked"
    href="/search"
    className="w-8 h-8 rounded-full hover:bg-gray-50 flex items-center justify-center place-self-center"
  >
    <SvgSearch className="h-5 w-5 text-gray-500" />
  </Link>
)
