import * as React from "react"
import type { SVGProps } from "react"
const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 25 25"
    {...props}
  >
    <path d="M20.933 5.787c-2.02-1.623-5.214-1.898-5.349-1.91a.506.506 0 0 0-.503.298l-.354 1.023c2.347.411 3.942 1.323 4.027 1.373a.832.832 0 0 1-.42 1.553.825.825 0 0 1-.418-.112c-.024-.015-2.363-1.346-5.414-1.346-3.052 0-5.393 1.332-5.416 1.346a.833.833 0 0 1-.838-1.441c.085-.05 1.685-.965 4.04-1.375-.182-.505-.365-1.014-.369-1.021a.5.5 0 0 0-.503-.298c-.135.011-3.33.286-5.376 1.932C2.97 6.797.833 12.576.833 17.572a.5.5 0 0 0 .067.25c1.476 2.592 5.5 3.27 6.416 3.3h.016a.509.509 0 0 0 .41-.208l.991-1.343c-2.175-.524-3.325-1.349-3.394-1.4a.834.834 0 0 1 .984-1.344c.031.02 2.154 1.505 6.177 1.505 4.04 0 6.156-1.491 6.177-1.506a.834.834 0 0 1 .986 1.344c-.07.05-1.214.873-3.384 1.397l.98 1.346a.506.506 0 0 0 .41.209l.015-.001c.918-.03 4.941-.708 6.416-3.3a.5.5 0 0 0 .067-.25c0-4.995-2.137-10.774-3.234-11.784M9.167 15.833c-.921 0-1.667-.933-1.667-2.084 0-1.15.746-2.083 1.667-2.083.92 0 1.666.932 1.666 2.083 0 1.151-.745 2.083-1.666 2.083m6.666 0c-.92 0-1.666-.933-1.666-2.084 0-1.15.745-2.083 1.666-2.083.921 0 1.667.932 1.667 2.083 0 1.151-.746 2.083-1.667 2.083" />
  </svg>
)
export default SvgDiscord
