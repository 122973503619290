"use client"
import { logShareLinkClick } from "../../app/(platform)/actions"
import { useSearchParams } from "next/navigation"
import { useEffect } from "react"
import { useAnonAnalytics } from "@store-platform/backend/api-client"
import { useSession } from "@clerk/nextjs"

export const ShareLinkObserver = () => {
  const { isLoaded, session } = useSession()
  const searchParams = useSearchParams()
  const { anonymousId } = useAnonAnalytics()
  useEffect(() => {
    if (!isLoaded) return
    const hash = searchParams.get("share")
    if (hash && anonymousId) {
      logShareLinkClick({
        hash,
        userId: session?.user.externalId ?? undefined,
        anonId: anonymousId,
        referrer: document.referrer,
      }).then()
    }
  }, [anonymousId, isLoaded, searchParams, session?.user])

  return null
}
