"use client"

import { useRouter } from "next/navigation"
import { SvgArrowLeft } from "@store-platform/ui/icons"
import { Button } from "@store-platform/ui/common"
import { cn } from "@store-platform/utils"

export const BackButton = ({ className }: { className?: string }) => {
  const router = useRouter()

  return (
    <Button
      // Naked variant because this style is only used here (rounded-full, hover bg gray)
      variant="naked"
      onClick={() => router.back()}
      className={cn(
        "flex flex-row items-center justify-center rounded-full p-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 sm:rounded-lg sm:hover:bg-gray-50 sm:hover:text-gray-900 sm:px-3 sm:py-2",
        className,
      )}
    >
      <SvgArrowLeft aria-hidden="true" />
      <p className="hidden sm:flex">Back</p>
    </Button>
  )
}
