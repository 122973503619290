import { Elements } from "@clerk/types"

export const defaultUserProfileElementStyling: Elements = {
  profileSectionPrimaryButton: {
    backgroundColor: "#eff3ff",
    color: "#366eff",
    minWidth: "132px",
    justifyContent: "center",
  },
  formButtonPrimary: {
    backgroundColor: "#366eff",
    color: "white",
    borderWidth: 0,
    boxShadow: "none !important",
  },
}
