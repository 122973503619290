import * as React from "react"
import type { SVGProps } from "react"
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 22 25"
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M4.542 10c0-.345.28-.625.625-.625h11.666a.625.625 0 1 1 0 1.25H5.167A.625.625 0 0 1 4.542 10" />
      <path d="M10.558 3.725a.625.625 0 0 1 .884 0l5.833 5.833a.625.625 0 0 1 0 .884l-5.833 5.833a.625.625 0 1 1-.884-.884L15.95 10l-5.392-5.391a.625.625 0 0 1 0-.884" />
    </g>
  </svg>
)
export default SvgArrowRight
