/* eslint-disable prefer-const */
import {
  AttributeMap,
  Filters,
  ListingScreen,
  ParsedBrowsingState,
  SCREEN_DESKTOP,
  SCREEN_HEADSET,
  SCREEN_MOBILE,
  PLATFORM_PWA,
  RatingFilterQuery,
  SortQuery,
  ListingPlatform,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_MACOS,
  PLATFORM_WEB_APP,
  SCREENS,
  PLATFORMS,
} from "@store-platform/types"
import {
  Categories,
  categoryNew,
  parseCategoriesFromAttributes,
  parsePlatformsFromAttributes,
  parseScreensFromAttributes,
} from ".."
import {
  difference,
  first,
  intersection,
  omit,
  take,
  uniq,
  without,
} from "lodash-es"

// =================== Parsing state from URL (segments, search) ===================
function inferSortingFromSegments(segments: string[]): SortQuery | undefined {
  if (segments.includes("popular")) {
    return "highest_rated"
  } else if (segments.includes("trending")) {
    return "most_reviewed"
  } else if (segments.includes(categoryNew.id)) {
    return "newest"
  } else {
    return undefined
  }
}

function inferMinRatingFromSegments(
  segments: string[],
): RatingFilterQuery | undefined {
  const rating = segments.find((segment) => !isNaN(parseFloat(segment)))
  if (
    (rating && rating === "3") ||
    rating === "3.5" ||
    rating === "4" ||
    rating === "4.5" ||
    rating === "5"
  ) {
    return rating as RatingFilterQuery
  } else {
    return undefined
  }
}

function inferCategoriesFromSegments(segments: string[]): [string[], string[]] {
  const categories = intersection(Object.keys(Categories), segments)
  const withoutCategories = without(segments, ...categories)
  return [categories.map((cat) => `category.${cat}`), withoutCategories]
}

function inferScreensFromSegments(
  segment: string[],
): [ListingScreen[], string[]] {
  const screens: ListingScreen[] = []

  if (segment.includes("mobile")) {
    screens.push(SCREEN_MOBILE)
  }
  if (segment.includes("desktop")) {
    screens.push(SCREEN_DESKTOP)
  }
  if (segment.includes("headset")) {
    screens.push(SCREEN_HEADSET)
  }

  const withoutScreens = without(segment, "mobile", "desktop", "headset")

  return [screens, withoutScreens]
}

function inferPlatformsFromSegments(
  segment: string[],
): [ListingPlatform[], string[]] {
  const platforms: ListingPlatform[] = []

  if (segment.includes("ios")) {
    platforms.push(PLATFORM_IOS)
  }
  if (segment.includes("android")) {
    platforms.push(PLATFORM_ANDROID)
  }
  if (segment.includes("macos")) {
    platforms.push(PLATFORM_MACOS)
  }
  if (segment.includes("web-app")) {
    platforms.push(PLATFORM_WEB_APP)
  }

  const withoutPlatforms = without(
    segment,
    "ios",
    "android",
    "macos",
    "web-app",
  )

  return [platforms, withoutPlatforms]
}

export function parseSegmentsFromString(segments: string): string[] {
  return segments.split("_").filter((segment) => !!segment)
}

export function parseFiltersFromSegments(segments: string[]) {
  const filters: Filters = {}

  filters.sort_by = inferSortingFromSegments(segments)
  filters.min_rating = inferMinRatingFromSegments(segments)

  const sortAndFilterSegments = [
    "popular",
    "trending",
    "newest",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
  ]

  const attributes = without(segments, ...sortAndFilterSegments)

  const [categories, withoutCategories] =
    inferCategoriesFromSegments(attributes) || []
  const [screens, withoutScreens] =
    inferScreensFromSegments(withoutCategories) || []
  const [platforms, withoutPlatforms] =
    inferPlatformsFromSegments(withoutScreens) || []

  filters.attribute = uniq([
    ...withoutPlatforms,
    ...categories,
    ...screens,
    ...platforms,
  ])

  return filters
}

export function parseBrowsingState(filters?: Filters) {
  if (!filters) return {}

  let attributes
  if (typeof filters.attribute === "string") {
    attributes = [filters.attribute]
  } else {
    attributes = filters.attribute
  }

  const categories = parseCategoriesFromAttributes(
    // (attributes ?? []).map((attr) => `category.${attr}`),
    attributes ?? [],
  )
  const screens = intersection(
    SCREENS,
    // (attributes ?? []).map((attr) => `screen.${attr}`),
    attributes ?? [],
  )
  const platforms = intersection(
    PLATFORMS,
    // (attributes ?? []).map((attr) => `platform.${attr}`),
    attributes ?? [],
  )
  const remainingAttributes = difference(
    attributes,
    categories,
    screens,
    platforms,
  )

  return {
    attributes: [
      ...remainingAttributes,
      ...categories,
      ...screens,
      ...platforms,
    ],
    filters: omit(filters, "attribute"),
  } as ParsedBrowsingState
}

// =================== Generating URL from state ===================
function getSortingSegment(sortBy: SortQuery) {
  switch (sortBy) {
    case "highest_rated":
      return "popular"
    case "most_reviewed":
      return "trending"
    case "newest":
      return "newest"
    case "relevance":
    default:
      return ""
  }
}

function getScreenSegments(screens: ListingScreen[]) {
  const segments = []
  if (screens.includes(SCREEN_MOBILE)) segments.push("mobile")
  if (screens.includes(SCREEN_DESKTOP)) segments.push("desktop")
  if (screens.includes(SCREEN_HEADSET)) segments.push("headset")

  return segments
}

function getPlatformSegments(platforms: ListingPlatform[]) {
  const segments = []
  if (platforms.includes(PLATFORM_IOS)) segments.push("ios")
  if (platforms.includes(PLATFORM_ANDROID)) segments.push("android")
  if (platforms.includes(PLATFORM_MACOS)) segments.push("macos")
  if (platforms.includes(PLATFORM_PWA)) segments.push("pwa")
  if (platforms.includes(PLATFORM_WEB_APP)) segments.push("web-app")

  return segments
}

export function stateToQueryParams(
  state: ParsedBrowsingState,
): URLSearchParams {
  const params = new URLSearchParams()
  if (state.filters?.sort_by) {
    params.append("sort_by", state.filters.sort_by)
  }
  if (state.filters?.min_rating) {
    params.append("min_rating", state.filters.min_rating.toString())
  }

  if (state.attributes?.length) {
    state.attributes?.forEach((attribute) =>
      params.append("attribute", attribute),
    )
  }
  return params
}

function mergeStates(
  state: ParsedBrowsingState,
  changes: Partial<ParsedBrowsingState>,
) {
  return {
    ...state,
    ...changes,
    filters: { ...state.filters, ...changes.filters },
  }
}

export function generateSegmentUrl(state: ParsedBrowsingState): string {
  let segments = []
  if (state.filters?.sort_by) {
    segments.push(getSortingSegment(state.filters.sort_by))
  }
  if (state.filters?.min_rating) {
    segments.push(state.filters.min_rating)
  }

  let remainingAttributes = state.attributes ?? []
  const categories = parseCategoriesFromAttributes(remainingAttributes)
  const screens = parseScreensFromAttributes(remainingAttributes)
  const platforms = parsePlatformsFromAttributes(remainingAttributes)

  remainingAttributes = difference(
    remainingAttributes,
    categories,
    screens,
    platforms,
  )

  const attributes = [
    ...categories.map((cat) => cat.split(".")?.[1] ?? cat),
    ...getScreenSegments(screens),
    ...getPlatformSegments(platforms),
    ...remainingAttributes,
  ]
  segments = [...segments, ...attributes]

  return `/browse/${segments.join("_")}`
}

export function generateBrowsingURL(
  from: ParsedBrowsingState,
  to: ParsedBrowsingState,
): string {
  const state = mergeStates(from, to ?? {})
  if (state.searchQuery) {
    const prefix = `/search/${state.searchQuery}`
    const queries = stateToQueryParams(state)
    const search = queries.size > 0 ? `?${queries.toString()}` : ""

    return `${prefix}${search}`
  } else {
    return generateSegmentUrl(state)
  }
}

function toggleAttributeBrowsingState(
  state: ParsedBrowsingState,
  attributeSlug: string,
  toggle: boolean,
): ParsedBrowsingState {
  const attributes = toggle
    ? [...(state.attributes ?? []), attributeSlug]
    : state.attributes?.filter((attr) => attr !== attributeSlug)

  return mergeStates(state, { attributes })
}

export function getToggleAttributeLink(
  state: ParsedBrowsingState,
  attributeSlug: string,
  toggle: boolean,
) {
  const futureState = toggleAttributeBrowsingState(state, attributeSlug, toggle)
  return generateBrowsingURL(state, futureState)
}

// =================== SEO functions ===================
function getSortingTitle(sortBy?: SortQuery) {
  switch (sortBy) {
    case "highest_rated":
      return "Popular"
    case "most_reviewed":
      return "Trending"
    case "relevance":
    default:
      return "Best"
  }
}

function getScreenTitle(screens: ListingScreen[]): string | undefined {
  const segments = []
  if (screens.includes(SCREEN_MOBILE)) segments.push("Mobile")
  if (screens.includes(SCREEN_DESKTOP)) segments.push("Desktop")
  if (screens.includes(SCREEN_HEADSET)) segments.push("Headset")

  return segments.length > 1 ? undefined : segments[0]
}

function getPlatformTitle(platform: ListingPlatform[]): string | undefined {
  const segments = []
  if (platform.includes(PLATFORM_IOS)) segments.push("iOS")
  if (platform.includes(PLATFORM_ANDROID)) segments.push("Android")
  if (platform.includes(PLATFORM_MACOS)) segments.push("MacOS")
  if (platform.includes(PLATFORM_WEB_APP)) segments.push("Web App")
  if (platform.includes(PLATFORM_PWA)) segments.push("PWA")

  return segments.length > 1 ? undefined : segments[0]
}

export function getStateTitle(
  state: ParsedBrowsingState,
  attributeMap: AttributeMap,
): string {
  const categories = take(
    parseCategoriesFromAttributes(state.attributes ?? []).map(
      (cat) => attributeMap[cat]?.name,
    ) || [],
    2,
  )

  const segments = [
    getSortingTitle(state.filters?.sort_by),
    formatCategoryList(categories),
  ]

  const screens = parseScreensFromAttributes(state.attributes ?? [])
  const screen = getScreenTitle(screens ?? [])
  if (screen) segments.push(screen)

  const platforms = parsePlatformsFromAttributes(state.attributes ?? [])
  const platform = getPlatformTitle(platforms)
  if (platform) segments.push(platform)

  segments.push("Apps")
  return segments.join(" ")
}

export function getStateDescription(state: ParsedBrowsingState): string {
  const categories = parseCategoriesFromAttributes(state.attributes ?? [])

  const firstCategory = Categories[first(categories)?.split(".")?.[1] || ""]
  if (!firstCategory) return "Browse through all of the apps on Store.app"
  return firstCategory?.description?.short
}

function formatCategoryList(categories: string[]) {
  if (!Array.isArray(categories) || categories.length === 0) {
    return ""
  }

  const numCategories = categories.length

  if (numCategories === 1) {
    return categories[0]
  } else if (numCategories === 2) {
    return categories.join(" and ")
  } else {
    const lastCategory = categories.pop()
    return `${categories.join(", ")}, and ${lastCategory}`
  }
}
