"use client"

import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"

import { SvgChevronDown } from "@store-platform/ui/icons"
import { cn } from "@store-platform/utils"

const NavBarAccordion = AccordionPrimitive.Root

const NavBarAccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border-b", className)}
    {...props}
  />
))
NavBarAccordionItem.displayName = "AccordionItem"

const NavBarAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 mt-2 text-xs text-gray-500 lg:px-2 lg:text-start justify-between py-4 font-medium transition-all ease-in-out hover:underline [&[data-state=open]>svg]:rotate-180",
        className,
      )}
      {...props}
    >
      {children}
      <SvgChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200 ease-in-out" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
NavBarAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const NavBarAccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    withAnimation?: boolean
  }
>(({ className, children, withAnimation = true, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm ease-in-out data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      {
        "transition-all": withAnimation,
      },
    )}
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))

NavBarAccordionContent.displayName = AccordionPrimitive.Content.displayName

export {
  NavBarAccordion,
  NavBarAccordionItem,
  NavBarAccordionTrigger,
  NavBarAccordionContent,
}
