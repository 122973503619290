"use client"

import { create } from "zustand"

type ListingSearchStore = {
  ref: React.RefObject<HTMLInputElement>
}

export const useListingSearch = create<ListingSearchStore>((set) => ({
  ref: { current: null },
}))
