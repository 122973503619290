"use client"

import * as Form from "@radix-ui/react-form"
import { z, ZodNumber, ZodObject } from "zod"
import { FormFieldSlider, FormFieldSliderProps } from "./FormFieldSlider"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"

export type FormFieldSliderAutoSaveProps = {
  label?: string
  action: (value: number) => unknown
  error?: string
  className?: string
  value?: number
} & Pick<FormFieldSliderProps, "min" | "max" | "step" | "defaultValue">

export function Slider(props: FormFieldSliderAutoSaveProps) {
  const schema = z.object({ value: z.number().min(props.min).max(props.max) })

  const form = useForm<z.infer<ZodObject<{ value: ZodNumber }>>>({
    resolver: zodResolver(schema),
    defaultValues: { value: props.defaultValue },
  })

  useEffect(() => {
    form.register("value", {
      onChange: (e) => {
        props.action(e.target.value)
      },
    })
  }, [form, props])

  useEffect(() => {
    if (props.value) {
      form.setValue("value", props.value)
    }
  }, [form, props.value])

  return (
    <Form.Root>
      <FormFieldSlider
        name="value"
        control={form.control}
        min={props.min}
        max={props.max}
        step={props.step}
        label={props.label}
        className={props.className}
      />
    </Form.Root>
  )
}
