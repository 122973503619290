"use client"
import { ReviewCard } from "../ReviewCard/ReviewCard"
import { plural } from "@store-platform/utils"
import { useReviewsModal } from "../../hooks"
import {
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalTitle,
} from "../ResponsiveModal/ResponsiveModal"

export const ReviewsModal = () => {
  const {
    payload: { reviews, showAppIcon },
    isOpen,
    onOpenChange,
  } = useReviewsModal()
  return (
    <ResponsiveModal open={isOpen || false} onOpenChange={onOpenChange}>
      <ResponsiveModalContent className="md:min-w-[650px] h-[800px] flex flex-col gap-2 overflow-auto">
        <ResponsiveModalTitle className="text-left text-3xl text-gray-900 font-semibold">
          {plural(reviews.length, "Review")}
        </ResponsiveModalTitle>
        <div
          data-vaul-no-drag
          className="flex flex-col gap-2 overflow-y-auto scrollbar-thin"
        >
          {reviews.map((r) => (
            <ReviewCard
              key={r.id}
              review={r}
              variant="default"
              expandStyle="default"
              className="min-h-fit"
              showAppIcon={showAppIcon}
            />
          ))}
        </div>
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
