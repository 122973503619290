"use client"
import { useSetClientState } from "@store-platform/ui/common"
import { useEffect } from "react"
import { UAParser } from "ua-parser-js"

export const DeviceTypeObserver = () => {
  const set = useSetClientState()

  useEffect(() => {
    const uaParser = new UAParser(navigator.userAgent)
    const { browser, os, device } = uaParser.getResult()

    set({
      userAgentBrowser: browser,
      userAgentOS: os,
      userAgentDevice: device,
    })
  }, [set])

  return <></>
}
