"use client"

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  useAlertDialog,
} from "@store-platform/ui/common"
import React from "react"

export const GenericAlertDialog = () => {
  const { payload, isOpen, onOpenChange } = useAlertDialog()
  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="m-0 text-[17px] font-medium">
            {payload.title}
          </AlertDialogTitle>
          <AlertDialogDescription className="mt-4 mb-5 text-[15px] leading-normal">
            {payload.description}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="max-sm:gap-y-2">
          {payload.cancel !== false && (
            <AlertDialogCancel asChild>
              <Button variant="outline" onClick={payload.onCancel}>
                Cancel
              </Button>
            </AlertDialogCancel>
          )}
          <AlertDialogAction asChild>
            <Button variant="default" onClick={payload.onConfirm}>
              {payload.confirmText}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
