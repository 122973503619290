import { SCREEN_DESKTOP } from "@store-platform/types"
import { Badge } from "../Badge/Badge"
import { parseScreensFromAttributes, screenIcons } from "@store-platform/utils"

export const ScreenBadges = ({ attributes = [] }: { attributes: string[] }) => {
  const screens = parseScreensFromAttributes(attributes)
  let filtered = screenIcons.filter((screen) => screens.includes(screen.id))

  if (!filtered.length)
    filtered = [
      screenIcons.find(
        (p) => p.id === SCREEN_DESKTOP,
      ) as (typeof screenIcons)[0],
    ]

  return filtered.map((p) => (
    <Badge key={p.id} variant="secondary" className="p-1 aspect-1">
      <p.Icon className="w-3 h-3" />
    </Badge>
  ))
}
