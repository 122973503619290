"use client"

import { Button, ButtonProps, PwaInstallDialogElement } from ".."
import { ReactNode } from "react"

type PwaInstallDialogTriggerProps = ButtonProps & {
  children: ReactNode
  trackingSource?: string
  sideEffect?: () => void
}

export const PwaInstallDialogTrigger = ({
  children,
  trackingSource,
  sideEffect,
  ...props
}: PwaInstallDialogTriggerProps) => {
  return (
    <Button
      {...props}
      tracker={{
        obj: "Layout",
        action: "PWA Install Triggered",
        properties: {
          source: trackingSource,
        },
      }}
      onClick={() => {
        const dialog = document.getElementById(
          "pwa-install-dialog",
        ) as PwaInstallDialogElement
        if (!dialog) return

        sideEffect?.()
        dialog.show()
      }}
    >
      {children}
    </Button>
  )
}
