import * as React from "react"
import type { SVGProps } from "react"
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 2.292a7.708 7.708 0 1 0 0 15.416 7.708 7.708 0 0 0 0-15.416M1.042 10a8.958 8.958 0 1 1 17.916 0 8.958 8.958 0 0 1-17.916 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M6.104 13.527a3.095 3.095 0 0 1 2.11-.819h3.572c.784 0 1.543.29 2.11.82.568.53.896 1.258.896 2.028v1.11a.625.625 0 0 1-1.25 0v-1.11c0-.41-.174-.811-.5-1.115a1.846 1.846 0 0 0-1.256-.483H8.214c-.479 0-.93.178-1.257.483a1.525 1.525 0 0 0-.499 1.115v1.11a.625.625 0 0 1-1.25 0v-1.11c0-.77.328-1.499.896-2.029M10 6.458a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75M6.875 8.333a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgProfile
