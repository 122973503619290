import * as React from "react"
import type { SVGProps } from "react"
const SvgAlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.838 2.175a2.292 2.292 0 0 1 3.147.85l6.665 11.663a2.292 2.292 0 0 1-1.983 3.437H3.336a2.291 2.291 0 0 1-2.003-3.436v-.002L7.999 3.025c.2-.353.49-.646.84-.85m1.154.938a1.042 1.042 0 0 0-.907.528v.002l-6.669 11.67a1.042 1.042 0 0 0 .912 1.562H16.666a1.042 1.042 0 0 0 .901-1.562l-.001-.003-6.667-11.667-.001-.002a1.041 1.041 0 0 0-.906-.528"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10 6.875c.345 0 .625.28.625.625v3.333a.625.625 0 1 1-1.25 0V7.5c0-.345.28-.625.625-.625M9.375 14.167c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H10a.625.625 0 0 1-.625-.625"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgAlertTriangle
