import { SvgClaimed } from "@store-platform/ui/icons"
import { Tooltip } from "../Tooltip/Tooltip"
import { Badge } from "../Badge/Badge"
import { cn } from "@store-platform/utils"

type ClaimedListingBadgeProps = {
  withText?: boolean
}
export const ClaimedListingBadge = ({ withText }: ClaimedListingBadgeProps) => {
  return (
    <Tooltip caption="Listing claimed by owner">
      <Badge
        variant={withText ? "default" : "naked"}
        className={cn("cursor-default", { "p-1": !!withText })}
      >
        <SvgClaimed className="fill-blue-500 h-5 w-5" />
        {withText && "Claimed"}
      </Badge>
    </Tooltip>
  )
}
