import * as React from "react"
import type { SVGProps } from "react"
const SvgClaimed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    {...props}
  >
    <path d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0m-5.176-1.176a.6.6 0 1 0-.848-.848L9.2 10.752 8.024 9.576a.6.6 0 1 0-.848.848l1.6 1.6a.6.6 0 0 0 .848 0z" />
  </svg>
)
export default SvgClaimed
