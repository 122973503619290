import * as React from "react"
import type { SVGProps } from "react"
const SvgLightbulb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M7.292 16.389c0-.345.28-.625.625-.625h4.166a.625.625 0 1 1 0 1.25H7.917a.625.625 0 0 1-.625-.625M7.986 18.333c0-.345.28-.625.625-.625h2.778a.625.625 0 1 1 0 1.25H8.61a.625.625 0 0 1-.625-.625M6.612 6.89a4.791 4.791 0 0 1 8.18 3.383 3.855 3.855 0 0 1-1.234 2.886c-.459.46-.702.878-.798 1.399a.625.625 0 0 1-1.23-.226c.155-.837.561-1.474 1.153-2.065a2.604 2.604 0 0 0 .859-1.978v-.01a3.541 3.541 0 1 0-7.084 0c0 .566.119 1.243.85 1.979.595.548 1 1.27 1.159 2.064a.625.625 0 0 1-1.226.245 2.576 2.576 0 0 0-.808-1.416c-1.025-1.025-1.225-2.052-1.225-2.873 0-1.27.505-2.49 1.404-3.388M10 1.042c.345 0 .625.28.625.625v1.666a.625.625 0 1 1-1.25 0V1.667c0-.346.28-.625.625-.625M3.666 3.666a.625.625 0 0 1 .884 0l1.175 1.175a.625.625 0 1 1-.884.884L3.666 4.55a.625.625 0 0 1 0-.884M1.042 10c0-.345.28-.625.625-.625h1.666a.625.625 0 1 1 0 1.25H1.667A.625.625 0 0 1 1.042 10M16.042 10c0-.345.28-.625.625-.625h1.666a.625.625 0 1 1 0 1.25h-1.666a.625.625 0 0 1-.625-.625M16.334 3.666a.625.625 0 0 1 0 .884l-1.175 1.175a.625.625 0 0 1-.884-.884l1.175-1.175a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgLightbulb
