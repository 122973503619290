"use client"

import { SidebarCta } from "./SidebarCta"
import { ClaimedApp } from "@store-platform/types"
import { SvgDiscord, SvgNavigation, SvgXLogo } from "@store-platform/ui/icons"
import { Link, LinkProps, Separator } from "@store-platform/ui/common"
import { DevAccessModal } from "../../(developer)/developer/_components/DevAccessModal"
import { cn } from "@store-platform/utils"
import { useUser } from "@clerk/nextjs"

type FooterLinkProps = Omit<LinkProps, "variant"> & {
  label: string | React.ReactNode
}

const FooterLink = (props: FooterLinkProps) => {
  return (
    <Link
      {...props}
      variant="naked"
      className="font-normal disabled:pointer-events-none disabled:opacity-50 without-ring text-gray-500 fill-gray-500 text-xs"
    >
      {props.label}
    </Link>
  )
}

const FooterSeparator = () => {
  return <span className="hidden lg:flex text-xs text-gray-500">·</span>
}

type SidebarFooterProps = {
  app?: ClaimedApp
  mobileDrawer?: boolean
}

export function SidebarFooter(props: SidebarFooterProps) {
  const { user } = useUser()
  const email = user?.primaryEmailAddress?.emailAddress

  return (
    <div>
      <SidebarCta app={props.app} />

      <div className="flex flex-col space-y-1">
        <Separator className="mt-3" />
        <div className="flex flex-col space-x-1 pt-3">
          <div
            className={cn("flex items-center", {
              "flex-col lg:flex-row gap-1": !props.mobileDrawer,
              "flex-row justify-center gap-3": !!props.mobileDrawer,
            })}
          >
            <FooterLink href="/privacy" label="Privacy" prefetch={false} />
            <FooterSeparator />
            <FooterLink href="/terms" label="Terms" prefetch={false} />
            <FooterSeparator />
            <FooterLink
              tracker={{
                obj: "Layout",
                action: "External Link Followed",
                properties: {
                  destination: "Email Contact",
                },
              }}
              href="mailto:support@store.app"
              label="Contact"
            />
            <FooterSeparator />
            <FooterLink
              tracker={{
                obj: "Layout",
                action: "External Link Followed",
                properties: {
                  destination: "Twitter",
                },
              }}
              href="https://x.com/storedotapp"
              label={<SvgXLogo width={12} height={12} />}
              asExternal
            />
            <FooterSeparator />
            <FooterLink
              tracker={{
                obj: "Layout",
                action: "External Link Followed",
                properties: {
                  destination: "Discord",
                },
              }}
              href="https://discord.gg/nGSS8r9fH9"
              label={<SvgDiscord width={14} height={14} />}
              asExternal
            />
          </div>
        </div>
        <div className="lg:flex lg:justify-center">
          <div className="-mx-2 lg:inline-flex flex-col flex-1 text-[10px] text-gray-400 lg:ml-0 ">
            <p className="hidden lg:inline-flex items-center text-start">
              <span>©</span>Store.app by PWA Labs, Inc.
              <Link
                href="/sitemap"
                variant="naked"
                aria-label="sitemap"
                className="ml-9"
                prefetch={false}
              >
                <SvgNavigation className="w-3 h-3" />
              </Link>
            </p>
            <p className="lg:hidden text-center">
              <span>©</span>Store.app PWA Labs, inc
            </p>
          </div>
        </div>
      </div>

      {!!email && <DevAccessModal email={email} />}
    </div>
  )
}
