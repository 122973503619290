"use client"

import { create } from "zustand"
import { BaseModalStoreType } from "@store-platform/types"

export const useAccountSettingsModal = create<BaseModalStoreType<undefined>>(
  (set) => ({
    payload: undefined,
    isOpen: false,
    open: () => set({ isOpen: true }),
    onOpenChange: (open: boolean) => set({ isOpen: open }),
  }),
)
