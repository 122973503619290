"use client"

import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import { Filters, Listing } from "@store-platform/types"
import { camelCaseKeysRecursive } from "@store-platform/utils"
import React, { useCallback, useMemo, useState } from "react"
import {
  Configure,
  InstantSearch,
  useConfigure,
  useInfiniteHits,
  useInstantSearch,
  useSearchBox,
  useSortBy,
} from "react-instantsearch"

export const searchPrefix = "/search/"

export type SearchComponentProps = {
  hits: Listing[]
  query: string
  search: (value: string) => void
  showMore: () => void
  isFirstPage: boolean
  isLastPage: boolean
  filter: (filters: Filters) => void
  sort: (sort: string) => void
  clear: () => void
  isLoading: boolean
}

type SearchProps = {
  indexName: string
  hitsPerPage: number
  children: React.FC<SearchComponentProps>
  filter?: string | undefined
}

const MEILI_SEARCH_ENDPOINT = process.env
  .NEXT_PUBLIC_MEILI_SEARCH_ENDPOINT as string
const MEILI_FRONTEND_KEY = process.env.NEXT_PUBLIC_MEILI_FRONTEND_KEY as string

const meili = instantMeiliSearch(MEILI_SEARCH_ENDPOINT, MEILI_FRONTEND_KEY, {
  placeholderSearch: false,
  finitePagination: true,
})

export const Search = (props: SearchProps) => {
  return (
    <InstantSearch
      searchClient={meili.searchClient}
      indexName={props.indexName}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure hitsPerPage={props.hitsPerPage || 20} />
      <SearchUtilities {...props} />
    </InstantSearch>
  )
}

const SearchUtilities = ({
  children,
  ...props
}: Pick<SearchProps, "children" | "filter">) => {
  const [query, setQuery] = useState<string>("")
  const { status } = useInstantSearch()
  const { refine, clear } = useSearchBox()
  const { refine: refineWithFilters } = useConfigure({
    filters: props.filter,
  })
  const { refine: refineWithSort } = useSortBy({
    items: [
      { label: "Relevance", value: "listings" },
      { label: "Highest Rated", value: "listings:rating_average:desc" },
      { label: "Most Reviewed", value: "listings:rating_count:desc" },
      { label: "Newest", value: "listings:first_published_at:desc" },
    ],
  })
  const {
    items: _hits,
    isFirstPage,
    isLastPage,
    showMore,
  } = useInfiniteHits<Listing>()

  const hits = useMemo(() => {
    return camelCaseKeysRecursive(_hits)
  }, [_hits])

  const search = useCallback((value: string): void => {
    setQuery(value)
    refine(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filter = useCallback((filters: Filters) => {
    const searchFilters = []
    if (filters?.min_rating) {
      searchFilters.push(`rating_average >= ${filters?.min_rating}`)
    }
    if (filters?.attribute) {
      // if a single platform is selected it will be a string instead of an array
      const attributes = Array.isArray(filters.attribute)
        ? filters.attribute
        : [filters.attribute]

      searchFilters.push(...attributes.map((p) => `attributes = ${p}`))
    }
    refineWithFilters({ filters: searchFilters.join(" AND ") })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sort = useCallback((sort: string) => {
    refineWithSort(sortByToQuery(sort))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = status === "loading" || status === "stalled"

  return children({
    hits,
    search,
    query,
    showMore,
    isFirstPage,
    isLastPage,
    filter,
    sort,
    clear,
    isLoading,
  })
}

const sortByToQuery = (sortBy: string) => {
  return (
    {
      highest_rated: "listings:rating_average:desc",
      most_reviewed: "listings:rating_count:desc",
    }[sortBy] || "listings"
  )
}
