import * as React from "react"
import type { SVGProps } from "react"
const SvgPlusSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10 6.042c.345 0 .625.28.625.625v2.708h2.708a.625.625 0 1 1 0 1.25h-2.708v2.708a.625.625 0 1 1-1.25 0v-2.708H6.667a.625.625 0 1 1 0-1.25h2.708V6.667c0-.346.28-.625.625-.625" />
    <path
      fillRule="evenodd"
      d="M1.875 4.167a2.292 2.292 0 0 1 2.292-2.292h11.666a2.292 2.292 0 0 1 2.292 2.292v11.666a2.292 2.292 0 0 1-2.292 2.292H4.167a2.292 2.292 0 0 1-2.292-2.292zm2.292-1.042c-.576 0-1.042.466-1.042 1.042v11.666c0 .576.466 1.042 1.042 1.042h11.666c.576 0 1.042-.466 1.042-1.042V4.167c0-.576-.466-1.042-1.042-1.042z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgPlusSquare
