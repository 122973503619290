"use client"

import {
  AttributeMap,
  AttributeRelationships,
  DEFAULT_ATTRIBUTE_RELATIONSHIPS,
} from "@store-platform/types"
import { getAttributeMap } from "@store-platform/utils"
import { create } from "zustand"

type AttributesState = {
  attributeMap: AttributeMap
  attributeRelationships: AttributeRelationships
  setData: (data: AttributeRelationships) => void
}

export const useAttributeState = create<AttributesState>((set) => ({
  attributeMap: getAttributeMap(DEFAULT_ATTRIBUTE_RELATIONSHIPS),
  attributeRelationships: DEFAULT_ATTRIBUTE_RELATIONSHIPS,
  setData: (data) =>
    set({
      attributeMap: getAttributeMap(data),
      attributeRelationships: data,
    }),
}))
