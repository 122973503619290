"use client"
import { AddToListModal, useAddToList } from "@store-platform/ui/common"
import {
  addAppToFavorites,
  addAppToList,
  createList,
  deleteList,
  listOwnedLists,
  removeAppFromList,
  renameList,
} from "../../app/(platform)/actions"
import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"
import { useAuth } from "@clerk/nextjs"

export const AddToListProvider = () => {
  const initState = useAddToList(useShallow((state) => state.initState))
  const { userId } = useAuth()

  useEffect(() => {
    if (!userId) return

    const initialize = async () => {
      const lists = await listOwnedLists()
      initState(lists, {
        addAppToFavorites,
        addAppToList,
        createList,
        renameList,
        deleteList,
        removeAppFromList,
      })
    }
    initialize().then()
  }, [initState, userId])

  return <AddToListModal />
}
