"use client"

import {
  DialogDescription,
  DialogTitle,
  ResponsiveModal,
  ResponsiveModalContent,
  useAuthModal,
  VisuallyHidden,
} from "@store-platform/ui/common"
import { AuthForm } from "../../app/auth/_components/AuthForm"

export const AuthModal = () => {
  const { mode, redirectTo, isOpen, onOpenChange } = useAuthModal()

  return (
    <ResponsiveModal open={isOpen} onOpenChange={onOpenChange}>
      <ResponsiveModalContent className="sm:!p-0">
        <VisuallyHidden>
          <DialogTitle>
            {mode === "sign-in" && "Sign In"}
            {mode === "sign-up" && "Sign Up"}
            {mode === "forgot-password" && "Forgot Password?"}
          </DialogTitle>
          <DialogDescription>
            {mode === "sign-in" && "Sign into your Store.app account"}
            {mode === "sign-up" && "Create a Store.app account"}
            {mode === "forgot-password" && "Reset your password"}
          </DialogDescription>
        </VisuallyHidden>
        <AuthForm mode={mode} redirectTo={redirectTo} navigation="state" />
      </ResponsiveModalContent>
    </ResponsiveModal>
  )
}
